import { ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { BehaviorSubject, Observable, Subject, catchError, combineLatest, debounceTime, distinctUntilChanged, forkJoin, of, range, retry, takeUntil } from 'rxjs';
import { EntityService } from 'src/app/services/entity.service';
import { EntityGridService } from 'src/app/services/entity-grid.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Criteria } from 'src/app/services/utility/request-utility.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { IndexeddbService } from 'src/app/services/indexdb.service';
declare const Excel: any;
var changeDetectionEventInProgress = false;
var hasChangedRow: boolean = false;
@Component({
  selector: 'app-entity',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, NgxMatSelectSearchModule, FormsModule, ReactiveFormsModule, MatProgressSpinnerModule, MatIconModule, MatButtonModule],
  templateUrl: './entity.component.html',
  styleUrls: ['./entity.component.scss']
})
export class EntityComponent {
  @ViewChild('searchInput') searchInput!: ElementRef;
  public version = environment.VERSION;
  public entityList: any = [];
  public modelList: any = [];
  public filteredEntityList: any = [];
  public filteredModelList: any = [];
  public loading: boolean = false;
  public pageNumber: number = 0;
  public defaultCodeSetting = "namecode";
  public headers: any;
  public entityId: any;
  public previousEntity: any;
  public currentPage: number = 0;
  public contentName: any = 'Loading Entity List...';
  public currentCriteria!: Criteria;
  public filter: any = [];
  public currentModel: any;
  public previousTableDatas: any;
  public selectedEntity: any;
  public modelId: any;
  public defaultModelId: any;
  public loadEntityData: any = [];
  public message: any;
  public btnTitle: any = 'Show Status';
  public icon_name: any = 'grid_on';
  public stopAdding: boolean = true;
  public editRecords: any = [];
  public deletedRecords: any;
  // public domainAttributeIds: any = [];
  public readColumns: any = [];
  public autoColumns: any = [];
  public validation: boolean = false;
  public subscription$ = new Subject<void>();
  public environment = environment;
  public disablePublishButton: boolean = false;
  public tableName: any;
  public searchInputSubject = new Subject<string>();
  showFilter: boolean = false;
  entity: any;
  query: any;
  public selectedHeaders: any = [];
  public entityMetaData: any;
  public domainAttributesArray: any = [];
  public domainEntity: any;
  public totalElementLimit: any;
  visibleHeaders: any;
  isCodeNull: boolean = false;
  previousQuery: any;
  savedFilter: any = [];
  isUpdate = false;
  public onChangedEventHandler: any;
  public filterApiBatches: number = 0;
  public totalFilterApiBatch: any;
  public intervalId: any;
  public overLay: boolean = false;
  public currentDialog: any;
  public updatedData: any;
  public deletionRowRange: any;
  public totalSelectedRowForDeletion: number = 0;
  public compareChangedData: any = [];

  roles: any[] = [];
  sheetLoaded: any = [];
  existingSheets: any = [];
  roleChanged: boolean = false;
  columnIndex: any = {};

  constructor(
    private entityService: EntityService,
    private entityGridService: EntityGridService,
    private cd: ChangeDetectorRef,
    private route: Router,
    private indexeddbService: IndexeddbService
  ) { }

  async ngOnInit() {
    this.officeReady();
    const { models } = await this.getAllModels();
    if (models) {
      this.modelList = models;
      this.filteredModelList = models;
      this.defaultModelId = this.filteredModelList[0].id
      if (!!this.defaultModelId) {
        this.handleModelChange(this.defaultModelId)
      }
    }

    // HANDLE THIS FOR FILTER THE ENTITY LIST
    this.searchInputSubject.pipe(
      debounceTime(500), // Adjust debounce time in milliseconds as needed
      distinctUntilChanged()
    ).subscribe((value: any) => {
      this.filteredEntityList = this.entityList.filter((item: any) => {
        return item.displayName
          .trim()
          .toLowerCase()
          .includes(value.trim().toLowerCase());
      });
    });
    this.totalElementLimit = environment.TOTALELEMENTS;
  }

  public officeReady() {
    Office.onReady(async (info) => {
      if (info.host === Office.HostType.Excel) {
        await Excel.run(async (context: any) => {
          let sheets = context.workbook.worksheets;
          let settings = context.workbook.settings;
          sheets.load("items/name, items/visibility");
          const tables = context.workbook.tables;
          tables.load('items/name');
          let previousRole = settings.getItemOrNullObject('roles')
          previousRole.load("value");
          const columnIndex = settings.getItemOrNullObject('columnIndex');
          columnIndex.load("value");
          await context.sync();

          this.columnIndex = columnIndex.value ? JSON.parse(columnIndex.value) : {};

          const lookupSheets = await sheets?.items?.filter((el: any) => el?.name?.includes("Lookup_")).map((item: any) => item.name);
          if (lookupSheets?.length > 0) {
            await this.indexeddbService.ensureDbOpen();
            this.addMissingStores(lookupSheets);
          }

          this.sheetLoaded = {};
          tables.items
            .forEach((item: any) => {
              const tableName = item.name;
              if (tableName.includes("Entity_")) {
                this.onChangedEventHandler = item.onChanged.add(this.excelChangeEvent.bind(this));
              }
            })

          const visibleSheets = sheets.items.filter((el: any) => el.visibility === "Visible");
          this.existingSheets = visibleSheets;
          visibleSheets.forEach((item: any) => { this.sheetLoaded[item] = false })
          let token: any = localStorage.getItem('token');
          this.roles = JSON.parse(atob(token.split('.')[1])).realm_access?.roles;
          if (JSON.stringify(this.roles) !== JSON.stringify(previousRole.value) && !previousRole.isNullObject) {
            this.roleChanged = true
          }
          // Handle this event when selecting the rows or change the worksheet
          Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, async (event: any) => {
            const sheet = context.workbook.worksheets.getActiveWorksheet();
            const range = context.workbook.getSelectedRange();
            await this.getCurrentTableName();
            const entityMetaData = settings.getItem(this.tableName);
            entityMetaData.load("value");
            range.load('address');
            await context.sync();
            let rowRange = this.getRangeDetails(range.address);
            const data = JSON.parse(entityMetaData.value);
            let headers = data.tableHeaders;
            console.log(headers);
            const permission = headers.findIndex((el: any) => ['READ', 'DENY'].includes(el.permission))
            // Selection for deleting records
            if (!!rowRange && rowRange !== '' && permission === -1) {
              this.deletionRowRange = rowRange;
              this.cd.detectChanges();
            }
            else {
              this.deletionRowRange = null;
              this.cd.detectChanges();
            }
          });
          await context.sync();
        });
      }
    });
  }

  public async currentSelectingSheetEntity() {
    await Excel.run(async (context: any) => {
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      let tableId: any = null;
      await this.getCurrentTableName();
      if (!!this.tableName) {
        tableId = this.tableName.split("_");
        tableId = tableId[1];
      }
      if (!!tableId) {
        this.selectedEntity = this.entityList.find((el: any) => el.id === +tableId);
        this.cd.detectChanges();
      }
      await context.sync();
    }).catch((err: any) => {
      console.log(err, 'err');
    })
  }

  public getRangeDetails(address: string): string {
    const match = address.match(/(\d+):(\d+)/);
    if (match) {
      return `${match[1]}:${match[2]}`;
    } else {
      // Extract rows and columns from the address
      const rangeParts = address.split("!");
      const range = rangeParts[1]; // E.g., "Sheet1!A1:B2"
      const [start, end] = range.split(":");
      if (start && end) {

        const startRow = start.replace(/[^0-9]/g, ''); // Extract row number from start cell
        const endRow = end.replace(/[^0-9]/g, ''); // Extract row number from end cell

        const startColumn = start.replace(/[0-9]/g, ''); // Extract column letters from start cell
        const endColumn = end.replace(/[0-9]/g, ''); // Extract column letters from end cell

        return `${startRow}:${endRow}`;
      }
      else {
        return ''
      }
    }
  }

  // Handle this function for getting the details from the office settings.
  public async getEntityMetaData() {
    if (!!this.entityId) {
      this.selectedEntity = this.entityList.find((item: any) => item.id === this.entityId);
      this.cd.detectChanges();
      await this.clearExcelCells(false, true, this.selectedEntity);
      this.showFilter = !!this.selectedEntity;
      this.excelRun()
    }
  }

  // HANDLE THIS FOR GET ALL MODELS
  public getAllModels(): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.entityService.getAllModels().subscribe(
        (models: any) => {
          resolve({
            models,
          });
        },
        (err: any) => {
          if (err.status === 401) {
            localStorage.removeItem('token');
            this.route.navigate(['/landing']);
          }
          this.loading = false;
          rejects({
            models: [],
          });
        }
      );
    });
  }
  // HANDLE THIS FOR GET THE ENTITY BY MODEL ID
  public handleModelChange(id: any) {
    this.loading = true;
    this.currentModel = this.modelList.find((el: any) => el.id === id);
    this.defaultModelId = this.currentModel?.id;
    this.searchInput?.nativeElement.value ? this.searchInput.nativeElement.value = '' : '';
    this.entityService.getEntityByModelId(id).subscribe(async (res: any) => {
      if (!!res) {
        this.entityList = res;
        this.filteredEntityList = res?.sort((a: any, b: any) => a.displayName.localeCompare(b.displayName));
        this.cd.detectChanges();
        // this.getEntityFromSheet();
        this.loading = false;
      }
    }, err => {
      if (err.status === 401) {
        localStorage.removeItem('token');
        this.route.navigate(['/landing']);
      }
      this.loading = false;
    })
  }


  async addMissingStores(result: any) {
    for (const item of result) {
      try {
        await Excel.run(async (context: any) => {
          this.overLay = true;
          let sourceRange = context.workbook.worksheets.getItem(item).getUsedRange();
          sourceRange.load('values');
          await context.sync();
          const values = sourceRange.values;
          const lookupdata: any[] = [];

          for (const row of values) {
            const rowData: any = {
              id: row[1],
              code: row[0],
              name: null,
              key:row[0].toString()
            };

            if (row[0].toString().includes('{') && row[0].toString().includes('}')) {
              rowData.code = row[0].split('{')[0];
              rowData.name = row[0].substring(row[0].indexOf('{') + 1, row[0].indexOf('}'));
              rowData.key=rowData.code.toString();
            }
            lookupdata.push(rowData);
          }

          try {
            await this.indexeddbService.addStore(item, 'key');
          } catch (storeError) {
            console.error('Error creating store:', item, storeError);
            return; // Exit early if store creation fails
          }

          try {
            await this.indexeddbService.addBulkData(item, lookupdata);
          } catch (bulkError) {
            console.error('Error adding bulk data to:', item, bulkError);
            this.overLay = false;
          }
          this.overLay = false;
          this.indexeddbService.closeDb();
        });
      } catch (excelError) {
        this.overLay = false;
        console.error('Error processing Excel data for item:', item, excelError);
      }
    }
  }
  // public domainIdHeaders(headers: any) {
  //   this.domainAttributeIds = [];
  //   headers.forEach((item: any) => {
  //     if (item.formType === 'LOOKUP' && item.totalElements < this.totalElementLimit) {
  //       const value = { name: `${item.selectColumn}_id`, selectColumn: item.selectColumn, formType: 'LOOKUPID', systemAttribute: false, width: 100, id: item?.referencedTableId };
  //       this.domainAttributeIds.push(value);
  //     }
  //   })
  // }


  // HANDLE THIS FUNCTION WHEN SELECT THE ENTITY
  public async entitySelection(entity: any, criteria?: any, pageNumber?: number) {
    if (this.entity?.id !== entity.id) {
      let dialog: any;
      let args = 'continue';
      if (this.entity && this.loadEntityData.length != 0) {
        args = 'close';
        Office.context.ui.displayDialogAsync(
          `${environment.URL}/#/confirm`,
          { height: 30, width: 30 },
          (item) => {
            dialog = item.value;
            dialog.addEventHandler(
              Office.EventType.DialogMessageReceived,
              async (arg: any) => {
                // if (arg.message === 'send') {
                //   const data = { message: 'The content of active worksheet will be deleted. Do you want to continue?', yes: 'Yes', no: 'No' }
                //   dialog.messageChild(JSON.stringify(data));
                // } else {
                if (arg.message === 'send') {
                  setTimeout(() => {
                    const data = { message: 'The content of active worksheet will be deleted. Do you want to continue?', yes: 'Yes', no: 'No' }
                    dialog.messageChild(JSON.stringify(data));
                  }, 800);
                }
                if (arg.message === 'close') {
                  dialog.close();
                }
                if (arg.message == 'continue') {
                  this.loadEntity(entity)
                }
              });
          });
      }
      if (args == 'continue') {
        this.loadEntity(entity)
      }
    }
  }

  public async loadEntity(entity: any) {
    await this.currentSheetMetaData(entity)
    this.isCodeNull = false
    this.showFilter = false
    this.message = '';
    this.loading = true;
    this.selectedEntity = entity;
    this.loadEntityData = [];
    this.cd.detectChanges();
    this.pageNumber = 0;
    this.subscription$.next();
    await this.clearExcelCells(false, false, entity);
    this.headers = this.entityGridService.getGridHeaders(
      entity,
      false,
      this.defaultCodeSetting
    );

    // await this.getAllDomainApi();

    this.showFilter = true
    this.loading = false;
    this.contentName = '';
    this.cd.detectChanges();
    const modelName = { modelName: this.currentModel?.displayName };
    entity = { ...entity, ...modelName };
    this.entity = entity
  }

  public getEntityDataCounts(ids: any[]): Observable<any>[] {
    return ids.map(id =>
      this.entityService.getEntityDataCount(id, 0, 1, []));
  }

  public async openDialogBox(entity: any) {
    this.domainEntity = null;
    this.filterApiBatches = 0;
    this.totalFilterApiBatch = '...';
    this.currentDialog = null;
    // let currentSheetEntity = this.entityList.find((elm: any) => elm.id === this.entityId) || null;
    // currentSheetEntity = !!currentSheetEntity && (!!entity && entity?.id === currentSheetEntity?.id) ? currentSheetEntity : entity;
    // let domainBasedIds = this.headers.filter((el: any) => el.formType === 'LOOKUP');
    // if (domainBasedIds.length !== 0) {
    //   const uniqueReferencedTableIds = Array.from(
    //     new Set(domainBasedIds.map((elm: any) => elm.referencedTableId))
    //   );
    //   const data: any = new Promise((resolve) => { combineLatest([...this.getEntityDataCounts(uniqueReferencedTableIds)]).subscribe(res => resolve(res)) });
    //   this.domainEntity = uniqueReferencedTableIds.map((id: any, index: number) => {
    //     return { id: id, count: data.__zone_symbol__value[index] };
    //   });
    // }
    this.pageNumber = 0;
    const token = localStorage.getItem('token');
    Office.context.ui.displayDialogAsync(`${environment.URL}/#/filter/${entity?.groupId}/${entity?.id}/${token}`, { height: 80, width: 90 },
      (item) => {
        const dialog = item.value;
        this.currentDialog = dialog;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (arg: any) => {
          if (arg.message === 'send') {
            const data = { query: this.query, headers: this.selectedHeaders, filters: this.filter, savedFilter: this.savedFilter, isUpdate: this.isUpdate }
            dialog.messageChild(JSON.stringify(data));
          }
          else {
            // dialog.close();
            let item: any;
            if (arg.message !== 'close') { item = JSON.parse(decodeURIComponent(arg.message)); }
            const totalDomain = +item?.data?.totalDomainEntity;
            if (!!item?.data) {
              this.domainEntity = item?.data?.domainEntity;
            }
            if (arg.message === 'token-expired') {
              localStorage.removeItem('token');
              this.route.navigate(['/landing']);
            }
            if (item?.type === 'progress') {
              this.overLay = true;
              this.intervalId = setInterval(() => {
                dialog.messageChild(JSON.stringify({ currentBatch: this.filterApiBatches, totalBatch: this.totalFilterApiBatch }));
                this.contentName = `Loading batch ${this.filterApiBatches} of ${this.totalFilterApiBatch}`,
                  this.cd.detectChanges();
                if (this.filterApiBatches === this.totalFilterApiBatch) {
                  clearInterval(this.intervalId);
                  this.cd.detectChanges();
                }
              }, 10);
            }
            else {
              this.overLay = false;
              this.cd.detectChanges();
            }
            if (arg.message == 'close') {
              this.query = this.previousQuery
              this.isUpdate = false
              dialog.close();
              this.cd.detectChanges();
            }
            if (arg.message !== 'close' && arg.message !== 'send') {
              if (this.loadEntityData.length !== 0) {
                setTimeout(() => {
                  Office.context.ui.displayDialogAsync(
                    `${environment.URL}/#/confirm`,
                    { height: 30, width: 30 },
                    (item) => {
                      const dialog = item.value;
                      dialog.addEventHandler(
                        Office.EventType.DialogMessageReceived,
                        async (args: any) => {
                          if (args.message == 'continue') {
                            this.loading = true;
                            await this.clearExcelCells(false, true, entity);
                            this.loadData(entity, arg)
                          }
                        });
                    });
                }, 1000);
              }
              else {
                this.loading = true;
                await this.clearExcelCells(true, false, entity);
                this.loadData(entity, arg)
              }
            }
          }
        }
        )
      })
  }


  public async loadData(entity: any, arg: any) {
    this.contentName = 'Loading Entity Data...';
    this.loadEntityData = [];
    this.domainAttributesArray = [];
    // await this.clearExcelCells(true, false);
    this.loading = true;
    this.cd.detectChanges();
    const attribute = JSON.parse(decodeURIComponent(arg.message));
    this.headers = attribute.data.headers;
    this.selectedHeaders = attribute.data.selectedAttibutes;
    this.domainAttributesArray = attribute.data.attributeNamesToReplaceCodeWithId?.filter((el: any) => el.count >= this.totalElementLimit)
    this.headers.push({
      dataType: "number",
      name: "__ROW_IDENTIFIER",
      formType: "FREE_FORM",
      column: "is_valid",
      options: undefined,
      isFilterable: true,
      referencedTableId: null,
      selectColumn: '__ROW_IDENTIFIER',
      systemAttribute: false,
      width: 100
    },
      {
        dataType: "string",
        column: 'inputstatus',
        name: "Input Status",
        formType: "FREE_FORM",
        options: undefined,
        isFilterable: true,
        referencedTableId: null,
        selectColumn: 'inputstatus',
        systemAttribute: false,
        width: 100
      },
      {
        dataType: "string",
        column: 'inputmessage',
        name: "Input Message",
        formType: "FREE_FORM",
        options: undefined,
        isFilterable: true,
        referencedTableId: null,
        selectColumn: 'inputmessage',
        systemAttribute: false,
        width: 100
      },
      {
        dataType: "string",
        name: "Validation Message",
        formType: "FREE_FORM",
        column: "validationmessage",
        options: undefined,
        isFilterable: true,
        referencedTableId: null,
        selectColumn: 'validationmessage',
        systemAttribute: false,
        width: 100
      }
    )
    this.visibleHeaders = [...this.headers];
    // this.domainIdHeaders(this.headers);
    // this.headers.push(...this.domainAttributeIds);

    this.filter = attribute.data.filters;
    this.query = attribute.data.query;
    this.previousQuery = attribute.data.query;
    const lookupDataLength = this.headers.filter((el: any) => el.formType === 'LOOKUP').length;
    await this.indexeddbService.ensureDbOpen();
    await this.getAllDomainApi(lookupDataLength);
    this.entityService.clearCache();
    const validationStatusIndex = this.headers.findIndex((item: any) => item.column === 'validationstatus');
    const idIndex = this.headers.findIndex((item: any) => item.column === 'id');
    const inputStatusIndex = this.headers.findIndex((item: any) => item.column === 'inputstatus');
    const validationMessageIndex = this.headers.findIndex((item: any) => item.column === 'validationmessage');
    const inputMessageIndex = this.headers.findIndex((item: any) => item.column === 'inputmessage');

    // Ensure all required columns exist
    if (validationStatusIndex !== -1 && idIndex !== -1 && inputStatusIndex !== -1) {
      // Remove the objects from their original positions
      const validationStatusItem = this.headers.splice(validationStatusIndex, 1)[0];
      const idItem = this.headers.splice(idIndex - (idIndex > validationStatusIndex ? 1 : 0), 1)[0];
      const inputStatusItem = this.headers.splice(inputStatusIndex - (inputStatusIndex > validationStatusIndex ? 1 : 0) - (inputStatusIndex > idIndex ? 1 : 0), 1)[0];
      const validationMessageItem = this.headers.splice(validationMessageIndex - (validationMessageIndex > validationStatusIndex ? 1 : 0) - (validationMessageIndex > idIndex ? 1 : 0) - (validationMessageIndex > inputStatusIndex ? 1 : 0), 1)[0];
      const inputMessageItem = this.headers.splice(inputMessageIndex - (inputMessageIndex > validationStatusIndex ? 1 : 0) - (inputMessageIndex > idIndex ? 1 : 0) - (inputMessageIndex > inputStatusIndex ? 1 : 0) - (inputMessageIndex > validationMessageIndex ? 1 : 0), 1)[0];

      // Add them back to the beginning of the array in the desired order
      this.headers.unshift(idItem, validationStatusItem, validationMessageItem, inputStatusItem, inputMessageItem);
    }
    this.addEntityMetaData(this.filter, this.selectedEntity);
    this.excelRun();
  }

  public loadLookupDataByEntityId(entity: any, criteria: any): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.entityService.getEntitesByIds(entity?.id, criteria).pipe(
        takeUntil(this.subscription$),
        retry(1), // Retry the request up to 3 times before failing
        catchError((err) => {
          console.log(err);
          if (err.status === 401) {
            localStorage.removeItem('token');
            this.route.navigate(['/landing']);
          }
          this.loading = false;
          this.overLay = false
          return of({ entityData: [] }); // Return an empty entityData array in case of an error
        })
      ).subscribe(
        (entityData: any) => {
          resolve({
            entityData,
          });
        },
        (err: any) => {
          if (err.status === 401) {
            localStorage.removeItem('token');
            this.route.navigate(['/landing']);
          }
          // this.loading = false;
          resolve({
            entityData: [],
          });
        }
      );
    });
  }

  /**
   * Handle this for formating the table records
   * @param data any
   * @returns 
   */
  public formatingTableRowData(data: any) {
    const domainTotalElement = this.headers.filter((el: any) => el.totalElements >= this.totalElementLimit);
    const domainTotalElementNames = domainTotalElement?.map((att: any) => `${att.selectColumn}_id`);
    this.headers = this.headers.filter((elm: any) => !domainTotalElementNames.includes(elm.name));

    // let domainAttributeIndex: any = [];
    const headerPermission = this.headers.find((elm: any) => ['READ', 'DENY'].includes(elm.permission));

    return data.map((el: any, index: number) => {
      if ([undefined, 'FALSE', false].includes(el.validationstatus)) {
        el = { ...el, validationstatus: 'Validation Failed' };
      } else if (['isValid', 'TRUE', true, 'nan', null].includes(el.validationstatus)) {
        el = { ...el, validationstatus: 'Validation Succeeded' };
      }
      return this.headers.map((header: any, headerIndex: number) => {
        const columnLetter = ExcelHelper.getColumnLetter(headerIndex);
        if (header.dataType == 'date' && !!el[header.column]) {
          el[header.column] = moment(el[header.column]).parseZone().format(header.dateFormat || "MM/DD/YYYY")
        }

        if (header.selectColumn === '__ROW_IDENTIFIER') {
          const rowIndexFormula = `=ROW()`;
          return rowIndexFormula;
        }
        if (header.selectColumn === 'inputstatus') {
          const inputStatus = ['', null, undefined].includes(el?.validationstatus) ? 'Error' : ['DENY', 'READ'].includes(headerPermission?.permission) ? 'Read Only' : 'Unchanged';
          return inputStatus;
        }
        if (header.formType === "LOOKUP") {
          // const val: any = { columnLetter: columnLetter, name: header.selectColumn };
          // domainAttributeIndex.push(val);
          const name = el[`${header.selectColumn}_name`];
          const code = el[`${header.selectColumn}_code`];
          const value = header.totalElements > this.totalElementLimit ? code : !!name && !!code ? `${code}{${name}}` : !name && code ? `${code}` : name;
          return value
        }
        // else if (header.formType === "LOOKUPID") {
        //   const letterIndex = index + 2;
        //   const attIndex = domainAttributeIndex.find((el: any) => el.name === header.selectColumn);
        //   const value = `=IFERROR(INDEX(Lookup_${header.id}!B:B, MATCH(${attIndex?.columnLetter}${letterIndex}, Lookup_${header.id}!A:A, 0)), "")`;
        //   return value
        // }
        else {
          return el[header?.selectColumn]
        }
      });
    });
  }

  public async refreshEntityRecords() {
    if (this.roleChanged && !this.sheetLoaded[this.entity.displayName.trim().replace(/\s+/g, "")] && this.existingSheets.includes(this.entity.displayName)) {
      this.checkSheetPermission()
    }
    else {
      this.loading = true;
      this.overLay = true;
      this.icon_name = 'grid_on';
      this.btnTitle = 'Show Status';
      this.contentName = 'Loading domain-based attributes...';
      this.totalFilterApiBatch = '...';
      this.filterApiBatches = 0;
      this.loadEntityData = [];
      this.domainAttributesArray = [];
      this.pageNumber = 0;
      this.cd.detectChanges();
      Office.context.ui.displayDialogAsync(
        `${environment.URL}/#/loader`, { height: 50, width: 60 },
        (asyncResult: any) => {
          const dialog = asyncResult.value;
          this.currentDialog = dialog;
          dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (arg: any) => {
            if (arg.message === 'start') {
              this.intervalId = setInterval(() => {
                dialog.messageChild(JSON.stringify(this.contentName));
                this.cd.detectChanges();
                // if ((this.filterApiBatches !== undefined && this.totalFilterApiBatch !== undefined) && this.filterApiBatches === this.totalFilterApiBatch) {
                //   clearInterval(this.intervalId);
                //   this.cd.detectChanges();
                // }
              }, 10);
            }
          })
        });
      const lookupDataLength = this.headers?.filter((el: any) => el.formType === 'LOOKUP').length;
      this.entityService.clearCache();
      await this.indexeddbService.ensureDbOpen();
      await this.getAllDomainApi(lookupDataLength);
      this.entityService.clearCache();
      this.getEntityMetaData();
    }
  }

  public async refreshExcelData(entity: any) {
    if (hasChangedRow) {
      Office.context.ui.displayDialogAsync(
        `${environment.URL}/#/confirm`,
        { height: 30, width: 40 },
        (item) => {
          const dialog = item.value;
          dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            async (arg: any) => {
              // if (arg.message === 'send') {
              //   const data = { message: 'The content of active worksheet will be deleted. Do you want to continue?', yes: 'Yes', no: 'No' }
              //   dialog.messageChild(JSON.stringify(data));
              // } else {
              if (arg.message === 'send') {
                setTimeout(() => {
                  const data = { message: 'Some changes have not been published. If you continue, all unpublished changes will be lost. Do you want to continue?', yes: 'Yes', no: 'No' }
                  dialog.messageChild(JSON.stringify(data));
                }, 800);
              }
              if (arg.message == 'close') {
                dialog.close();
              }
              if (arg.message == 'continue') {
                dialog.close();
                hasChangedRow = false;
                setTimeout(() => {
                  this.refreshEntityRecords();
                }, 500);
              }
            });
        });
    }
    else {
      this.refreshEntityRecords();
    }
  }

  /**
   * Handle this function for publish the records
   */
  public publish() {

    if (this.roleChanged && !this.sheetLoaded[this.entity.displayName.trim().replace(/\s+/g, "")] && this.existingSheets.includes(this.entity.displayName)) {
      this.checkSheetPermission()
    }
    else {
      Excel.run(async (context: any) => {
        var sheet = context.workbook.worksheets.getActiveWorksheet();
        const tableName = await this.getCurrentTableName();
        const table = sheet.tables.getItem(this.tableName);
        const headerRowRange = table.getHeaderRowRange();
        headerRowRange.load("values");
        await context.sync();
        if (!headerRowRange.values[0].includes("Code")) {
          Office.context.ui.displayDialogAsync(
            `${environment.URL}/#/missing/column`, { height: 30, width: 30 },
            (asyncResult: any) => {
              const dialog = asyncResult.value;
              dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
                // if (arg.message === 'send') {
                //   const data = { message: "Critical columns missing : 'Code'. Detaching from worksheet", yes: 'Close' }
                //   dialog.messageChild(JSON.stringify(data));
                // } else {
                dialog.close();
                // }
              })
            }
          );
        }
        else {
          Office.context.ui.displayDialogAsync(
            `${environment.URL}/#/loader`, { height: 50, width: 60 },
            (asyncResult: any) => {
              const dialog = asyncResult.value;
              this.currentDialog = dialog;
              dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (arg: any) => {
                if (arg.message === 'start') {
                  this.intervalId = setInterval(() => {
                    dialog.messageChild(JSON.stringify(this.contentName));
                    this.cd.detectChanges();
                    // if ((this.filterApiBatches !== undefined && this.totalFilterApiBatch !== undefined) && this.filterApiBatches === this.totalFilterApiBatch) {
                    //   clearInterval(this.intervalId);
                    //   this.cd.detectChanges();
                    // }
                  }, 10);
                  await this.currentSelectingSheetEntity();
                  this.validateOrPublishRecords('publish');
                }
              })
            });
        }
        return context.sync();
      })
    }

  }

  // Handle this function to add the data in excel sheet
  public async excelRun(data?: any, criteria?: any) {
    await Excel.run(async (context: any) => {
      this.stopAdding = false;
      let columnCount: any;
      // Get the active worksheet
      const sheet = context.workbook.worksheets.getActiveWorksheet();
      const pageSize = environment.PAGESIZE;
      // initial column data validation
      this.addPreHeaderDataValidation(sheet, this.headers, context);

      columnCount = this.headers.length;
      let headerRange = sheet.getRangeByIndexes(0, 0, 1, columnCount);
      // let range = sheet.getRangeByIndexes(0, 0, rowCount - (rowCount - 1), columnCount);
      // Freeze the headers
      sheet.freezePanes.freezeRows(1);
      const rangeA1 = sheet.getRange("C2");
      rangeA1.select();

      // Set headers dynamically
      const headers = this.headers.map((header: any) => header.name);
      headerRange.values = [headers];

      let lastId;
      for (let currentPage = 0, totalPages = 1; currentPage < totalPages; currentPage++) {
        let myCriteria = {
          filters: this.filter,
          sorters: [{ direction: "ASC", property: "id" }],
          pager: {
            pageNumber: currentPage,
            pageSize: environment.PAGESIZE,
            // Todo: need to uncomment once API Issue is resolved
            ...(currentPage !== 0) ? {
              overRideOffset: true,
              greaterThan: true,
              id: lastId,
            } : {}
          }
        }
        const customCriteria = !criteria ? myCriteria : criteria;
        const { entityData } = await this.loadLookupDataByEntityId(this.selectedEntity, customCriteria);
        if (entityData.content.length > 0) {
          this.filterApiBatches = currentPage + 1;
          this.totalFilterApiBatch = entityData.totalPages;
          this.contentName = `Loading batch ${this.filterApiBatches} of ${this.totalFilterApiBatch}`,
            this.cd.detectChanges();
          const item: any = entityData?.content || null;
          if (!item) {
            return;
          }
          lastId = item[item.length - 1]['id'] || null;
          const tableData = await this.formatingTableRowData(item);
          this.cd.detectChanges();
          if (tableData.length > 0) {
            columnCount = this.headers.length;
            const startRowIndex = currentPage * pageSize + 1;
            const tableRange = sheet.getRangeByIndexes(startRowIndex, 0, tableData.length, columnCount);
            tableRange.values = tableData;
            totalPages = entityData.totalPages;
            await context.sync();
          }
        }
        else {
          // If tableData is empty, create a default row with the __ROW_IDENTIFIER formula
          const exampleColumnName = '__ROW_IDENTIFIER';
          const startRowIndex = currentPage * pageSize + 1;
          const exampleColumnIndex = this.headers.findIndex((header: any) => header.name === exampleColumnName);

          if (exampleColumnIndex !== -1 && exampleColumnIndex < this.headers.length && this.headers[exampleColumnIndex].name === '__ROW_IDENTIFIER') 
          {
            const formula = `=ROW()`;
        
            // Apply formula '=ROW()' to the column in the Excel sheet
            const columnRange = sheet.getRangeByIndexes(startRowIndex, exampleColumnIndex, 1, 1);
            columnRange.formulas = [[formula]];
          }
          this.loading = false;
          this.overLay = false;
          this.cd.detectChanges();
        }
      }
      // await context.sync();
      const usedRange = sheet.getUsedRange();
      usedRange.load('rowCount, columnCount'); // Load rowCount and columnCount properties

      await context.sync();

      const startRow = 0;
      const startColumn = 0;
      const rowCount = usedRange.rowCount;
      const columnCounts = usedRange.columnCount;

      // Get the range of the data to be converted to a table
      const dataRange = sheet.getRangeByIndexes(startRow, startColumn, rowCount, columnCounts);

      // Convert the range to a table
      const table = sheet.tables.add(dataRange, true /* hasHeaders */);
      this.tableName = `Entity_${this.selectedEntity?.id}`;
      table.name = this.tableName;

      this.columnIndex[this.tableName] = columnCount;
      const settings = context.workbook.settings;
      settings.add('columnIndex', JSON.stringify(this.columnIndex));

      this.addPostDataValidation(sheet, this.headers, context, data);
      this.sheetFormating(sheet)
      // Get and save the table data in array
      const existingTableRow = sheet.tables.getItem(this.tableName);
      const newRange = existingTableRow.getDataBodyRange();
      newRange.load("values");
      await context.sync();
      this.onChangedEventHandler = table.onChanged.add(this.excelChangeEvent.bind(this));
      this.previousTableDatas = newRange.values;
      this.sheetFormating(sheet);
      await this.readOnlyColumns(sheet, this.headers, newRange.values);
      if (!sheet) {
        return;
      }
      if (!!this.currentDialog) {
        this.overLay = false;
        this.currentDialog.close();
      }
      if (!!this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.entityId = this.selectedEntity?.id;
      this.loading = false;
      this.overLay = false
      this.sheetLoaded[this.entity.displayName.trim().replace(/\s+/g, "")] = true;
      this.message = {
        message: 'Record loaded successfully',
        type: 'success'
      };
      this.contentName = '';
      this.indexeddbService.closeDb()
      this.cd.detectChanges();
      this.clearMessage();
    })
      .catch((error: any) => {
        console.error("Error adding data to rows:", error);
        this.loading = false;
      });
  }

  public sheetFormating(sheet: any) {
    sheet.getUsedRange().format.autofitColumns();
    sheet.getUsedRange().format.autofitRows();
    sheet.getUsedRange().format.horizontalAlignment = "Left";
    const unLockColumns = sheet.getRange("A1:XFD1048576");

    if (unLockColumns) {
      unLockColumns.format.protection.locked = false;
    }
    const headerVal = this.headers.map((el: any) => { return el.name.toLowerCase() });
    headerVal.forEach((item: any, index: any) => {
      const columnLetter = ExcelHelper.getColumnLetter(index);
      if (!!columnLetter && ['id', 'validation status', '__row_identifier', 'input status', 'validation message', 'input message'].includes(item) || item.endsWith('_id')) {
        sheet.getRange(`${columnLetter}:${columnLetter}`).columnHidden = true;
      }
      return
    });
  }

  public async readOnlyColumns(sheet: any, header: any, record: any) {
    let readonly = false;
    await header.forEach((item: any, index: number) => {
      const columnLetter = ExcelHelper.getColumnLetter(index);
      const lastIndex = record.length + 1;
      let lockColumn = sheet.getRange(`${columnLetter}1:${columnLetter}${lastIndex}`);
      if (['READ', 'DENY'].includes(item.permission)) {
        this.readColumns.push(`${columnLetter}1:${columnLetter}${lastIndex}`);
        if (lockColumn) {
          readonly = true;
          lockColumn.format.protection.locked = true;
          lockColumn.format.fill.color = "#c5c5c5";
        }
      }
    })
    // if (readonly && environment.URL === 'https://excel-addin.spriced.nrp.simadvisory.com') {
    //   sheet.protection.protect({
    //     allowAutoFilter: true,
    //     allowDeleteColumns: true,
    //     allowDeleteRows: true,
    //     allowFormatCells: true
    //   }, "SIM8Eight#");
    // }
  }

  public async updateCellWithStatus(sheet: any, record: any, context: any, deletedEntity?: any, type?: any) {
    let messages: any = [];
    // Filter the new records
    const newInsertedRows = record?.result?.filter((elm: any) => elm.UPSERT_STATUS === "INSERTED" && elm.UPSERT_STATUS_MSG === "Success") || null;
    if (!!record) {
      if (![null, undefined].includes(record.ruleValidations))
      // if record.rulevalidation are not empty
      {
        for (let i = 0; i < record.ruleValidations.length; i++) {
          let ruleVal = record.ruleValidations[i];
          let resultVal = record?.result[i];
          const rowNumber = ruleVal?.output.__ROW_IDENTIFIER;
          let inputValue = sheet.getRange(`D${rowNumber}`);
          let inputMessageValue = sheet.getRange(`E${rowNumber}`);
          let validationInput = sheet.getRange(`B${rowNumber}`);
          let validationMessageField = sheet.getRange(`C${rowNumber}`);
          inputMessageValue.values = [['']];
          if(resultVal.UPSERT_STATUS === 'ERROR'){
            inputValue.values = [['Error']];
            inputMessageValue.values = [[resultVal.UPSERT_STATUS_MSG]];
            // inputValue.getUsedRange().format.font.color = "#c5261f";

            // this can impact the performance
            if (rowNumber && !!this.headers?.length) {
              const fullRow = sheet.getRangeByIndexes(rowNumber - 1, 0, 1, this?.headers?.length -1);
              fullRow.getUsedRange().format.fill.color = "#ffcecb";
            }
          }
          for (let validationIndex = 0; validationIndex < ruleVal.ruleResults.length; validationIndex++) {
            const failedRule = ruleVal.ruleResults[validationIndex];
            if (!failedRule.success) {
              messages.push(failedRule.message);
              this.validation = true;
              validationInput.values = [['Validation Failed']];
              inputValue.values = [['Error']];
              const range = sheet.getRange(`B${rowNumber}`);
              range.getUsedRange().format.font.color = "#c5261f";
              const validationMessageColumnRange = sheet.getRange(`C${rowNumber}`);
              validationMessageColumnRange.getUsedRange().format.font.color = "#c5261f";
              this.validation = false;
            }
            const inputStatus = this.readColumns.length > 0 ? 'Read Only' : resultVal.UPSERT_STATUS === 'ERROR' ? 'Error' :  'Unchanged';
            if (type === 'changed') {
              inputValue.values = [[inputStatus]];
            }
          }
          const concatenatedString = messages.join('\n');
          // find the particular row and then adding the message
          validationMessageField.values = [[concatenatedString]];
          messages = [];
          if (ruleVal.succes) {
            validationInput.values = [["Validation Succeeded"]];
            const range = sheet.getRange(`B${rowNumber}`);
            range.getUsedRange().format.font.color = "#000000";
          }
        }
      }
      else {
        // if record.rulevalidation are empty
        if (record.result.length > 0) {
          for (let i = 0; i < record.result.length; i++) {
            let ruleVal = record.result[i];
            const rowNumber = ruleVal?.__ROW_IDENTIFIER;
            let inputMessageValue = sheet.getRange(`E${rowNumber}`);
            let inputValue = sheet.getRange(`D${rowNumber}`);
            let validationInput = sheet.getRange(`B${rowNumber}`);
            inputMessageValue.values = [['']];
            const inputValues = this.readColumns.length > 0 ? ['Read Only'] : ['Unchanged'];
            const validationValues = ['Validation Succeeded'];
            if (type === 'changed') {
              inputValue.values = [inputValues];
            }
            validationInput.values = [validationValues];
          }
        }
      }
    }
    // Handle this when inserted new row then add id's in id column
    if (!!newInsertedRows && newInsertedRows?.length > 0) {
      let sheet = context.workbook.worksheets.getActiveWorksheet();
      const usedRange = sheet.getUsedRange();
      usedRange.load('rowCount, columnCount');
      await context.sync();

      for (let item of newInsertedRows) {
        let idColumn = sheet.getRange(`A${item.__ROW_IDENTIFIER}`);
        idColumn.values = [[item.id]]
      }

    }
    await context.sync();

    // Handle this function when delete the records
    if (!!deletedEntity && deletedEntity?.length > 0) {
      if (this.deletedRecords.length > 0) {
        this.deletedRecords = this.deletedRecords.map((elm: any, index: number) => ({
          ...elm,
          deleted: deletedEntity[index]
        }));
        const alreadyDeletedRows = this.deletedRecords?.filter((elm: any) => !elm.deleted).map((el: any) => { return el.__ROW_IDENTIFIER }) || [];
        const deletedRows = this.deletedRecords?.filter((elm: any) => !!elm.deleted).map((el: any) => { return el.__ROW_IDENTIFIER }) || [];
        try {
          await Excel.run(async (context: any) => {
            // Specify the worksheet
            let sheet = context.workbook.worksheets.getActiveWorksheet();
            const usedRange = sheet.getUsedRange();
            usedRange.load('rowCount, columnCount');
            await context.sync();

            if(alreadyDeletedRows?.length > 0)
            {
              for (let rowNum of alreadyDeletedRows) {
                let inputMessageValue = sheet.getRange(`E${rowNum}`);
                let inputValue = sheet.getRange(`D${rowNum}`);
                inputValue.values = [['Error']];
                // inputValue.format.font.color = "#c5261f";
                inputMessageValue.values = [['There are some mismatch on the data provided for update please reload data before publishing.']];
                // this can impact the performance
                if (rowNum && !!this.headers?.length) {
                  const fullRow = sheet.getRangeByIndexes(rowNum - 1, 0, 1, this?.headers?.length -1);
                  fullRow.getUsedRange().format.fill.color = "#ffcecb";
                }
              }
            }

            // Array of row numbers to delete
            let rowsToDelete = deletedRows; // Specify the rows you want to delete
            rowsToDelete.sort((a: any, b: any) => b - a);

            // Delete each row
            for (let rowNum of rowsToDelete) {
              let range = sheet.getRange(`A${rowNum}:XFD${rowNum}`);
              range.delete(Excel.DeleteShiftDirection.up);
            }

            await context.sync();
            this.totalSelectedRowForDeletion = 0;
            this.deletedRecords = null;
          });
        } catch (error) {
          console.error(error);
        }
      }
    }
  }

  public removeFilters() {
    if (this.filter.length > 0) {
      const myCriteria = {
        filters: this.filter,
        sorters: [{ direction: "DESC", property: "id" }],
        pager: { pageNumber: 0, pageSize: environment.PAGESIZE }
      }
      this.excelRun();
    }
  }

  // Handle this function to add the validation in columns
  private addPostDataValidation(sheet: any, headers: any, context: any, record: any) {
    headers.forEach(async (el: any, index: number) => {
      const columnLetter = ExcelHelper.getColumnLetter(index);

      if (el.name === "Code" && el.isAuto == false && !!sheet.tables) {
        let range = sheet.tables.getItem(this.tableName).columns.getItem(el.name).getDataBodyRange()
        range.dataValidation.ignoreBlanks = false
        range.dataValidation.rule = {
          custom: {
            formula: `=AND(COUNTIF(${columnLetter}:${columnLetter}, ${columnLetter}2)=1, OR(LEN(${columnLetter}2)>0,NOT(ISBLANK(${columnLetter}2))))`
          }
        };
        range.dataValidation.errorAlert = {
          message: "No duplicates or null allowed for code",
          showAlert: true, // The default is 'true'.
          style: Excel.DataValidationAlertStyle.stop,
          title: "Duplicate value entered"
        };
      }
      if (el.formType === "FREE_FORM") {
        switch (el.dataType) {
          case 'date':
            if (!!sheet.tables) {
              let range = sheet.tables.getItem(this.tableName).columns.getItem(el.name).getDataBodyRange()
              range.format.numberFormat = el.dateFormat;
              range.dataValidation.rule = {
                date: {
                  formula1: "01/01/1900",
                  formula2: "12/31/9999",
                  operator: Excel.DataValidationOperator.between
                }
              };
              range.dataValidation.errorAlert = {
                message: `Sorry, enter a correct date format. e.g ${el.dateFormat}`,
                showAlert: true,
                style: Excel.DataValidationAlertStyle.stop,
                title: "Data Type Mismatch"
              };
            }
            break;
          case 'number':
            if (!!sheet.tables) {
              let range = sheet.tables.getItem(this.tableName).columns.getItem(el.name).getDataBodyRange()
              // range.numberFormat = [['0.########']];
              range.dataValidation.rule = {
                custom: {
                  formula: `=AND(ISNUMBER(${columnLetter}2), OR(${columnLetter}2 >= 0, ${columnLetter}2 < 0))`
                }
              };
              range.dataValidation.errorAlert = {
                message: "Sorry, only numbers are allowed",
                showAlert: true, // The default is 'true'.
                style: Excel.DataValidationAlertStyle.stop,
                title: "Data Type Mismatch"
              };
            }
            break;
          default:
            break;
        }
      }
    })
  }

  addPreHeaderDataValidation(sheet: any, headers: any, context: any) {
    headers.forEach(async (el: any, index: number) => {
      const columnLetter = ExcelHelper.getColumnLetter(index);
      let columnRange = sheet.getRange(`${columnLetter}:${columnLetter}`);
      // const domainElementscount = this.headers.find((domAtt: any) => domAtt.name === el.selectColumn)?.elements;
      if (el.formType === "LOOKUP" && el.totalElements < this.totalElementLimit) {
        const showDropdown: boolean = el?.permission !== 'READ';
        let sourceRange = context.workbook.worksheets.getItem('Lookup_' + el.referencedTableId).getRange('A:A');

        columnRange.dataValidation.rule = {
          list: {
            source: sourceRange,
            formulaHidden: false,
            inCellDropDown: showDropdown,
          }
        };
      }

      if (el.formType === "LOOKUP" && el.totalElements > this.totalElementLimit) {
        columnRange.numberFormat = [['@']];
      }

      if (el.selectColumn !== 'id' && el.autoGenerated) {
        // const lastIndex = record.length;
        this.autoColumns.push(`${columnLetter}:${columnLetter}`)
        columnRange.format.fill.color = "#c5c5c5";
      }
      else if (el.formType === "FREE_FORM") {
        switch (el.dataType) {
          case 'string':
            columnRange.numberFormat = [['@']];
            break;
          case 'number':
            // columnRange.numberFormat = [['0.########']];
            columnRange.dataValidation.rule = {
              custom: {
                formula: `=AND(ISNUMBER(${columnLetter}2), OR(${columnLetter}2 >= 0, ${columnLetter}2 < 0))`
              }
            };
            columnRange.dataValidation.errorAlert = {
              message: "Sorry, only numbers are allowed",
              showAlert: true, // The default is 'true'.
              style: Excel.DataValidationAlertStyle.stop,
              title: "Data Type Mismatch"
            };
            break;

          default:
            break;
        }
      }
    })
  }

  public async showHideStatus(title: any) {
    const status = title === 'Hide Status';
    [this.btnTitle, this.icon_name] = status ? ['Show Status', 'grid_on'] : ['Hide Status', 'grid_off'];
    this.cd.detectChanges();
    await Excel.run(async (context: any) => {
      // Get the active worksheet
      const sheet = context.workbook.worksheets.getActiveWorksheet();

      const headerVal = this.headers.map((el: any) => { return el.name.toLowerCase() });
      headerVal.forEach((item: any, index: any) => {
        const columnLetter = ExcelHelper.getColumnLetter(index);
        if (!!columnLetter && ['validation status', 'input status', 'validation message', 'input message'].includes(item)) {
          sheet.getRange(`${columnLetter}:${columnLetter}`).columnHidden = status;
        }
        return
      })
      await context.sync();

    });
  }

  public async updateValidationStatus(item: any) {
    await Excel.run(async (context: any) => {
      let sheet = context.workbook.worksheets.getActiveWorksheet();

      await item.forEach((item: any) => {
        const cellAddress = `D${item.__ROW_IDENTIFIER}`;
        let inputValue = sheet.getRange(cellAddress);
        const inputStatus = 'Error';
        inputValue.values = [[inputStatus]];
        // const comments = sheet.comments;
        // comments.add(cellAddress, item.message);
        // const range = sheet.getRange("A" + item.__ROW_IDENTIFIER + ":XFD" + item.__ROW_IDENTIFIER);
        // range.getUsedRange().format.fill.color = "#ecc0be";
      })
      await context.sync();
    });
  }

  public async clearAllColors(sheet: any, context: any) {
    var range = sheet.getUsedRange();
    range.format.fill.clear();

    await this.readColumns.forEach((columnRange: any) => {
      const lockColumn = sheet.getRange(columnRange);
      lockColumn.format.fill.color = "#c5c5c5";
    })
    await this.autoColumns.forEach((columnRange: any) => {
      const lockColumn = sheet.getRange(columnRange);
      lockColumn.format.fill.color = "#c5c5c5";
    })
  }

  // Handle this function for edit the domain based attributes with code or name
  public async updateDomainAttributeFields(records: any) {
    const array = await Promise.all(records?.map(async (item: any) => {
      const publishItem: any = {};

      // Iterate over the keys of the object
      for (const headerItem of this.visibleHeaders) {
        const key = headerItem.selectColumn;
        const value = item[headerItem.selectColumn];

        if (!!value && headerItem.dataType === 'date') {
          const milliseconds = (value - 25569) * 86400 * 1000;
          var m = moment(milliseconds).utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format()
          publishItem[key] = m;
        }
        else if (headerItem && headerItem.formType === 'LOOKUP') {
          let attribute: any = null;
          if (!!value && value !== '#N/A' && headerItem.totalElements < this.totalElementLimit) {
            attribute = await this.getDomainId(value, headerItem.referencedTableId);
          }
          publishItem[key] = headerItem.totalElements > this.totalElementLimit ? value : !!attribute ? attribute.id : "";
          publishItem[`${key}_code`] = !!attribute ? attribute.code : null;
          publishItem[`${key}_name`] = !!attribute ? attribute.name : null;
        }
        else if (value === '#N/A') {
          publishItem[key] = null;
        }
        else {
          publishItem[key] = value;
        }
      }
      delete publishItem['validationstatus'];
      delete publishItem['inputstatus'];
      delete publishItem['validationmessage'];
      delete publishItem['inputmessage'];
      return publishItem;
    }));
    return array;
  }


  private async getDomainId(value: string, referencedTableId: string) {
    let code = value;
    if (value.toString().includes('{') && value.toString().includes('}')) {
      code = value.split('{')[0];
    }
    const record = await this.indexeddbService.getDataByKey('Lookup_' + referencedTableId, code.toString());
    return record;
  }

  public clearMessage() {
    setTimeout(() => {
      this.message = '';
      this.cd.detectChanges();
    }, 4000);
  }

  public combineHeaderAndData(header: any[], data: any[]): any[] {
    const combinedData: any[] = [];

    // Iterate through the data array
    for (const row of data) {
      const rowData: any = {};

      // Iterate through the header array and assign values from the corresponding row
      for (let i = 0; i < header[0].length; i++) {
        rowData[header[0][i]] = row[i];
      }

      // Push the row data object to the combinedData array
      combinedData.push(rowData);
    }

    return combinedData;
  }

  public async getAllDomainApi(lookupDataLength: number) {
    await Excel.run(async (context: any) => {
      const worksheets = context.workbook.worksheets;
      worksheets.load("items/name");
      await context.sync();
      for (const item of this.headers) {
        if (item.formType === 'LOOKUP') {
          // const existingSheet = worksheets.items.find((sheet: any) => sheet.name === item.selectColumn);
          // if (!existingSheet) {
          this.currentPage = 0;
          if (item?.totalElements < this.totalElementLimit) {
            await this.createSheetAndAddData(item);
          }
          // }
        }
      }
    });
  }

  public addDisplayNameInFilter(query?: any) {
    const updatedHeaders = this.headers.map((item: any) => {
      const res = item.column.split(",");
      if (res.length > 1) {
        const col = res.find((el: any) => el.endsWith("_code"));
        if (!!col) {
          return { ...item, column: col };
        }
      }
      return { ...item };
    });

    const validationStatus: any = [{
      dataType: "boolean",
      name: "Validation Status",
      formType: "FREE_FORM",
      column: "is_valid",
      options: undefined,
      isFilterable: true,
      referencedTableId: null,
      selectColumn: 'validationstatus',
      systemAttribute: false,
      width: 100
    }];
    updatedHeaders.push(validationStatus);

    if (!!query && query.rules) {
      query.rules.forEach((el: any) => {
        const item: any = updatedHeaders.find(
          (elm: any) => elm.column === el.field
        );
        if (el?.rules && el?.rules.length > 0) {
          this.addDisplayNameInFilter(el); // Recursively process sub-rules
        }
        if (!!item) {
          el.displayName = item.name;
        }
        if (el.field === "is_valid") {
          el.displayName = "Validation Status";
        }
        return;
      });
    }
    return updatedHeaders;
  }

  public async getLookupData(item: any): Promise<any> {
    let lookupdata: any = [];
    if (item?.referencedTableId) {
      let currentPage = 0;
      let totalPages = 1; // Initialize to 1 to enter the loop

      while (currentPage < totalPages) {
        const pageSize = item.totalElements >= environment.TOTALELEMENTS ? 1 : environment.PAGESIZE;
        const { entityData } = await this.loadLookupData(item?.referencedTableId, currentPage, pageSize);
        lookupdata.push(...entityData.content);
        // if (currentPage === 0 && entityData.totalElements >= this.totalElementLimit) {
        //   this.domainAttributesArray.push({ name: item.selectColumn, elements: entityData.totalElements });
        // }
        currentPage++;
        totalPages = entityData.totalPages;
        // item.totalElements = entityData.totalElements;
        // Check if totalPages exceeds 15,000
        if (entityData.totalElements >= this.totalElementLimit) {
          break;
        }
      }
    }
    return lookupdata;
  }

  public async createSheetAndAddData(item: any): Promise<any> {
    await Excel.run(async (context: any) => {
      return new Promise<void>(async (resolve, reject) => {
        try {
          let lookupdata: any = [];
          let dbData: any = [];
          let sheets = context.workbook.worksheets;
          sheets.load("items/name");
          await context.sync();

          const data = await this.getLookupData(item);
          const totalElements = this.domainAttributesArray.find((el: any) => el.name === item.selectColumn);
          if (!totalElements) {
            if (!!data?.length) {
              lookupdata = data;
              dbData=data
              dbData.forEach((item:any) => {
                item.key = item.code.toString();
            });
            }
            let newSheet: any;
            const existingSheet = sheets.items.find((sheet: any) => sheet.name === 'Lookup_' + item?.referencedTableId);
            if (!existingSheet) {
              newSheet = context.workbook.worksheets.add('Lookup_' + item?.referencedTableId);
              newSheet.visibility = 'hidden';
            }
            else {
              newSheet = context.workbook.worksheets.getItem('Lookup_' + item?.referencedTableId);
              let range = newSheet.getUsedRange();
              range.clear();
              await context.sync();
            }


            try {
              await this.indexeddbService.addStore('Lookup_' + item?.referencedTableId, 'key');
            } catch (storeError) {
              console.error('Error creating store:', 'Lookup_' + item?.referencedTableId, storeError);
              return; // Exit early if store creation fails
            }

            try {
              await this.indexeddbService.addBulkData('Lookup_' + item?.referencedTableId, dbData);
            } catch (bulkError) {
              console.error('Error adding bulk data to:', 'Lookup_' + item?.referencedTableId, bulkError);
            }



            // // Extract the "code" property from each object in the dataArray
            let names = lookupdata?.map((item: any) => {
              const name = !item.name ? '' : item.name;
              const code = !item.code ? '' : item.code;
              const value = !!name && !!code ? `${code}{${name}}` : !name && code ? `${code}` : name;
              return value
            });

            // Create a two-dimensional array with the extracted codes
            let values = names?.map((name: any) => [name]);
            let id = lookupdata?.map((item: any) => [item.id]);

            // Get the range starting from A1 to the end of the data
            let range = newSheet.getRange(`A1:A${values.length}`);
            let idRange = newSheet.getRange(`B1:B${id.length}`);

            // Set the values in the range
            range.values = values;
            idRange.values = id;

            // const domainAttSheets = context.workbook.worksheets.getItem(item?.selectColumn);
            // domainAttSheets.visibility = 'hidden';
          }
          // Sync to apply changes
          await context.sync().then(() => {
            resolve();
          }).catch((error: any) => {
            console.log(error, 'err')
            reject(error);
          });
        }
        catch (error) {
          console.log(error, 'err')
          reject(error);
        }
      });
    });
  }

  public loadLookupData(id: number, pageNumber: any, pageSize: number): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.entityService.loadLookupData(id, pageNumber, pageSize, [], [{ direction: "ASC", property: "code" }]).pipe(
        takeUntil(this.subscription$)
      ).subscribe(
        (entityData: any) => {
          resolve({
            entityData,
          });
        },
        (err: any) => {
          if (err.status === 401) {
            localStorage.removeItem('token');
            this.route.navigate(['/landing']);
          }
          this.loading = false;
          this.overLay = false
          rejects({
            entityData: [],
          });
        }
      );
    });
  }

  // Handle this function for clear the cells
  public async clearExcelCells(removeSheet: boolean, removeData: boolean, entity: any) {
    this.stopAdding = true;
    this.readColumns = [];
    this.autoColumns = [];
    try {
      await Excel.run(async (context: any) => {
        // let sheet = context.workbook.worksheets.getActiveWorksheet();
        let sheets = context.workbook.worksheets;
        sheets.load("items/name");
        // sheet.protection.unprotect("SIM8Eight#");
        await context.sync();
        const sheetName = this.handleSheetName(entity?.displayName);

        // Handle sheet removal and data removal
        if (removeSheet || removeData) {
          const existingSheetIndex = sheets.items.findIndex((sheet: any) => sheet.name === sheetName);
          let newSheet;

          if (existingSheetIndex !== -1) {
            const existingSheet = sheets.items[existingSheetIndex];
            await this.renameSheet(existingSheet, "Deleted");

            newSheet = sheets.add(sheetName);
            newSheet.activate();

            await context.sync();
            existingSheet.delete();
          } else {
            newSheet = sheets.add(sheetName);
            newSheet.activate();
          }

          newSheet.name = sheetName;
          await context.sync();

          if (removeSheet) {
            await this.deleteOtherSheets(sheets, sheetName, context);
          }
        }
      });
    } catch (error: any) {
      console.error("Error:", error.message);
      if (error instanceof OfficeExtension.Error) {
        console.error("Debug info:", JSON.stringify(error.debugInfo));
      }
    }
  }

  public async renameSheet(sheet: any, newName: string) {
    sheet.name = newName;
    await sheet.context.sync();
  }

  public async deleteOtherSheets(sheets: any, excludeSheetName: string, context: any) {
    for (const item of sheets.items) {
      if (['Sheet1'].includes(item.name)) {
        item.delete();
      }
      // if (item.name !== excludeSheetName) {
      //   item.delete();
      // }
    }
    await context.sync();
  }

  /**
   * Handling for sheetname
   * @param name string
   * @returns 
   */
  public handleSheetName(name: string) {
    let sheetName: any = name.trim();
    if (sheetName.length > 31) {
      sheetName = sheetName.substring(0, 31);
    }
    return sheetName;
  }

  public async convertSheetToTable(sheet: any, context: any, message?: any) {
    const newUsedRange = sheet.getUsedRange();
    newUsedRange.load('rowCount, columnCount');
    await context.sync();
    const startRow = 0;
    const startColumn = 0;
    const rowCount = newUsedRange.rowCount;
    const columnCounts = newUsedRange.columnCount;

    // Get the range of the data to be converted to a table
    const dataRange = sheet.getRangeByIndexes(startRow, startColumn, rowCount, columnCounts);

    // Convert the range to a table
    const table = sheet.tables.add(dataRange, true /* hasHeaders */);
    this.tableName = `Entity_${this.selectedEntity?.id}`;
    table.name = this.tableName;
    this.onChangedEventHandler = table.onChanged.add(this.excelChangeEvent.bind(this));
    const columnCRange = sheet.getRange('C:E').getUsedRange();
    columnCRange.format.load("wrapText");
    // Enable text wrapping for column C
    columnCRange.format.wrapText = false;

    const existingTableRow = sheet.tables.getItem(this.tableName);
    const newRange = existingTableRow.getDataBodyRange();
    newRange.load("values");
    await context.sync();
    this.previousTableDatas = newRange.values;
    // if all the record are got delete
    if (this.previousTableDatas?.length === 1 && 
      Object.values(this.previousTableDatas[0]).every( value => value?.toString()?.trim() === "")) {
        await this.manageRowIdentifierRow(sheet);
        await context.sync();
    }
    hasChangedRow = false;
    if (!this.isCodeNull && !message) {
      this.message = {
        message: 'Record published successfully.',
        type: 'success'

      }
    }
    this.loading = false;
    this.overLay = false
    this.disablePublishButton = false;
    this.indexeddbService.closeDb();
    if (!!this.currentDialog) {
      this.currentDialog.close()
    }
    // sheet.getUsedRange().format.autofitColumns();
    // sheet.getUsedRange().format.autofitRows();
    clearInterval(this.intervalId);
    this.cd.detectChanges();
    this.clearMessage();
  }
  checkSheetPermission() {
    Office.context.ui.displayDialogAsync(
      `${environment.URL}/#/missing/load`, { height: 30, width: 30 },
      (asyncResult: any) => {
        const dialog = asyncResult.value;
        dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
          dialog.close();
          setTimeout(() => {
            this.openDialogBox(this.entity);
          }, 100);
          // this.reloadData();
        })
      }
    );
  }

  // Handle this for validate and publish the records
  public async validateOrPublishRecords(type?: any): Promise<any> {
    await this.indexeddbService.ensureDbOpen();
    this.disablePublishButton = true;
    if (type == 'validate' && this.roleChanged && !this.sheetLoaded[this.entity.displayName.trim().replace(/\s+/g, "")] && this.existingSheets.includes(this.entity.displayName)) {
      this.checkSheetPermission()
    }
    else {
      this.updatedData = null;
      this.isCodeNull = false;
      this.loading = true;
      this.overLay = true;
      this.contentName = 'Loading Batches...'
      await Excel.run(async (context: any) => {
        return new Promise<void>(async (resolve, reject) => {
          try {
            const sheet = context.workbook.worksheets.getActiveWorksheet();
            if (this.onChangedEventHandler) {
              await this.removeEventHandler();
            }
            const preTable = context.workbook.tables.getItem(this.tableName);

            // Convert the table to a range
            var range = preTable.convertToRange();
            this.headers = [];
            const settings = context.workbook.settings;
            // const tableName = await this.getCurrentTableName();

            // let table = sheet.tables.getItem(this.tableName);
            const entityMetaData = settings.getItem(this.tableName);
            entityMetaData.load("value");
            // const headerRowRange = table.getHeaderRowRange();
            // headerRowRange.load("values");

            // Load the rowCount and columnCount properties of the table
            const usedRange = sheet.getUsedRange();
            usedRange.load('rowCount, columnCount');

            // const comments = sheet.comments;
            const comments = null
            // Loop through all comments to find if there's one for the specific cell
            // comments.load("items");

            await context.sync();

            const data = JSON.parse(entityMetaData.value);
            this.entityId = data?.entity_id;
            this.domainAttributesArray = data?.attributeNamesToReplaceCodeWithId;
            this.headers = data?.tableHeaders;

            const rowCount = usedRange.rowCount;
            const columnCount = usedRange.columnCount;
            const pageSize = type === 'publish' ? environment.PUBLISHRECORD : 500; // Number of rows to load at a time
            let startRow = 0;
            let totalBatchPublish = Math.ceil(rowCount / pageSize);
            let index = 1;
            let currentDataLength = 0;
            let record: any = null;

            const currentRange = sheet.getRangeByIndexes(startRow + 1, 0, rowCount, columnCount); // +1 to skip header
            currentRange.load("values");
            await context.sync();
            // Remove all colors from the table
            this.clearAllColors(sheet, context);
            while (startRow < rowCount) {
              const values = [...currentRange.values].splice(startRow, pageSize);
              if (values.length > 0) {
                currentDataLength += values.length;
                const result = await this.formatEditData(values);
                const items = result.editRecords;
                const deleteRecord = result.deletedRecords;
                if (items.length > 0 || deleteRecord.length > 0) {
                  if (type === 'publish') {
                    this.contentName = `Publishing changes batch ${index} of ${totalBatchPublish}...`;
                    record = await this.updateEntityRecords(items, deleteRecord, sheet, context);
                    const updatedRecord = record?.updatedRecord || null;
                    const deletedEntity = record?.deletedEntity || null;
                    if (!!updatedRecord || !!deletedEntity) {
                      // Handle this for update the table rows with status color etc
                      await this.updateCellWithStatus(sheet, updatedRecord, context, deletedEntity, 'changed');
                    }
                  }
                  else {
                    this.contentName = `Validating changes batch ${index} of ${totalBatchPublish}...`;
                    const { entityData } = await this.getAllValidationRules(items, index);
                    record = entityData;
                    if (!!record) {
                      await this.updateCellWithStatus(sheet, record, context);
                    }
                  }
                }
                startRow += pageSize;
                index++;
              }
              if (startRow >= rowCount) {
                await context.sync();
                this.loading = false;
                this.overLay = false;
                // Handle this for convert the rows into table
                await this.convertSheetToTable(sheet, context);
                break;
              }
            }
            resolve();
            return this.editRecords;
          }
          catch (error) {
            reject(error);
            if (!!this.currentDialog) {
              this.currentDialog.close()
            }
            clearInterval(this.intervalId);
            this.disablePublishButton = false;
            this.cd.detectChanges();
          }
        });
      }).catch((error: any) => {
        console.error(error, 'error');
      });
    }
  }

  /**
   * Handle this for update, delete and add new record api's
   * @param data any
   * @param deletedIds any
   * @param sheet any
   * @param record any
   * @returns 
   */
  public updateEntityInBluk(data: any, deletedIds: any, sheet: any, record: any, context: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Create observables based on conditions
      const updateObservable = data.data.length > 0
        ? this.entityService.updateEntityDataINBulk(this.entityId, data).pipe(takeUntil(this.subscription$),
        catchError((err: any) => {
          if (err?.error?.errorCode === 'DB_DM-001') {
            const errorMessage = err?.error?.message;
            const message = errorMessage && errorMessage.includes('-') ? errorMessage.split('-')[1] : errorMessage;
            this.errorMessageHandler(record, sheet, message);
            return of(null);
          } else if (err?.status === 400 && err?.error?.errorCode !== 'DB_DM-001') {
            if (err?.error?.message) {
              const errors = JSON.parse(err?.error?.message) || [];
              if (errors) {
                this.errorMessageHandler(errors, sheet);
              }
            }
            return of(null);
          }
          // Handle this when version is mismatch then reject all the api's
          else if(err?.error?.errorCode === 'VEX-400'){
            reject({ entityData: null });
            this.currentDialog.close();
            setTimeout(() => {
              this.handleMismatchVersionDialog(err?.error?.message, sheet, context);
            }, 500);
            return of(null);
          }
          return of(null);
        }))
        : of(null);

      const deleteObservable = deletedIds.length > 0
        ? this.entityService.deleteEntityDataINBulk(this.entityId, deletedIds).pipe(
          takeUntil(this.subscription$),
          catchError((error: any) => {
            if (error?.status === 409) {
              this.totalSelectedRowForDeletion = 0;
              const message = 'A referential integrity constraint violation occurred.';
              this.errorMessageHandler(this.deletedRecords, sheet, message);
            } else if (error?.status === 400) {
              if (error?.error?.message) {
                const errors = JSON.parse(error?.error?.message) || [];
                if (errors) {
                  this.errorMessageHandler(errors, sheet);
                }
              }
            }
            // Handle this when version is mismatch then reject all the api's
            else if(error?.error?.errorCode === 'VEX-400'){
              reject({ deletedEntity: null });
              this.currentDialog.close();
              setTimeout(() => {
                this.handleMismatchVersionDialog(error?.error?.message, sheet, context);
              }, 500);
              return of(null);
            }
            return of(null);
          }))
        : of(null);

      // Combine observables and subscribe to get results
      combineLatest([updateObservable, deleteObservable]).subscribe(
        ([entityData, deletedEntity]) => {
          resolve({
            entityData,
            deletedEntity
          });
        },
        (err: any) => {
          this.loading = false;
          this.overLay = false;

          if (err.status === 401) {
            localStorage.removeItem('token');
            this.route.navigate(['/landing']);
          } else if (err.status === 400) {
            if (err?.error?.message) {
              const errors = JSON.parse(err?.error?.message) || [];
              if (errors) {
                this.errorMessageHandler(errors, sheet);
              }
            }
          }

          resolve({
            entityData: null,
            deletedRecords: null
          });
        }
      );
    });
  }
  
  /**
   * Handle this function for mismatch the version
   */
  public handleMismatchVersionDialog(message: any, sheet: any, context: any)
  {
    Office.context.ui.displayDialogAsync(
      `${environment.URL}/#/confirm`,
      { height: 30, width: 30 },
        (item) => {
          const dialog = item.value;
          this.currentDialog = dialog;
          dialog.addEventHandler(
            Office.EventType.DialogMessageReceived,
            async (arg: any) => {
              if (arg.message === 'send') {
                setTimeout(() => {
                  const data = { message: message, ok: 'Ok' }
                  dialog.messageChild(JSON.stringify(data));
                }, 800);
              }
              if (arg.message == 'continue') {
                dialog.close();
                // const message = 'The record was not updated because the Excel plugin version is outdated.'
                this.convertSheetToTable(sheet, context, 'error');
              }
        });
    });
  }

  /**
   * Handling the error message
   * @param items any
   * @param sheet any
   * @param message string
   */
  public async errorMessageHandler(items: any, sheet: any, message?: string) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const cellAddress = `D${item.__ROW_IDENTIFIER}`;
      // this can impact the performance
      if (item.__ROW_IDENTIFIER && !!this.headers?.length) {
        const fullRow = sheet.getRangeByIndexes(item.__ROW_IDENTIFIER - 1, 0, 1, this?.headers?.length -1);
        fullRow.getUsedRange().format.fill.color = "#ffcecb";
      }
      let inputMessageField = sheet.getRange(`E${item.__ROW_IDENTIFIER}`);
      let inputValue = sheet.getRange(cellAddress);
      const inputStatus = 'Error';
      inputValue.values = [[inputStatus]];
      // const errorRange = sheet.getRange("A" + item.__ROW_IDENTIFIER + ":XFD" + item.__ROW_IDENTIFIER);
      // inputValue.getUsedRange().format.font.color = "#c5261f";
      // find the row with rowidentifier and then reasign the values
      inputMessageField.values = !!message ? [[message]] : [[item.message]];
    }
  }

  /**
   * Hanlde this for update, delete and add new records
   * @param record any
   * @param deleteRecord any 
   * @param sheet any
   * @returns 
   */
  public async updateEntityRecords(record: any, deleteRecord: any, sheet: any, context: any): Promise<any> {
    if (this.isCodeNull) {
      this.currentDialog.close();
      setTimeout(() => {
        Office.context.ui.displayDialogAsync(
          `${environment.URL}/#/missing/cell`, { height: 25, width: 20 },
          (asyncResult: any) => {
            const dialog = asyncResult.value;
            dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
              dialog.close();
              this.overLay = false
            })
          }
        );
      }, 500);
      return false;
    }

    if ((record.length > 0 || deleteRecord.length > 0) && !this.isCodeNull) {
      let finalDataPayload: any = [];
      this.editRecords = await this.updateDomainAttributeFields(record);
      let newRecord = this.editRecords.filter((el: any) => el.id === 0);
      this.editRecords.forEach((el: any) => {
        const item = this.compareChangedData.find((elm: any) => elm.id === el.id) || null;
        if (!!item) {
          finalDataPayload.push(item);
        }
        return el
      })
      finalDataPayload = await this.updateDomainAttributeFields(finalDataPayload);
      // Handle this for comparing the previous and current values and send only changes values
      this.editRecords = this.compareArrays(finalDataPayload, this.editRecords);
      this.editRecords = [...this.editRecords, ...newRecord];
      const attributeNames = this.headers
        .filter((elm: any) => elm.totalElements > this.totalElementLimit)
        .map((elm: any) => elm.selectColumn);

      let data = this.editRecords;
      data = attributeNames.length > 0 ? { data, attributeNamesToReplaceCodeWithId: attributeNames } : { data };
      const { entityData, deletedEntity } = await this.updateEntityInBluk(data, deleteRecord, sheet, record, context);
      const result = { updatedRecord: entityData, deletedEntity: deletedEntity }
      return result;
    }
  }

  // Handle this api for get all validation rules
  public async getAllValidationRules(record: any, vIndex: any): Promise<any> {
    const updatedRecords = await this.updateDomainAttributeFields(record)
    return new Promise((resolve, rejects) => {
      this.entityService.getValidationRules(this.entityId, updatedRecords, vIndex).subscribe(
        (entityData: any) => {
          resolve({
            entityData,
          });
        },
        (err: any) => {
          if (err.status === 401) {
            localStorage.removeItem('token');
            this.route.navigate(['/landing']);
          }
          resolve({
            entityData: [],
          });
        }
      );
    });
  }

  /**
   * Handle this for formating and filter the records
   * @param data any
   * @returns 
   */
  public async formatEditData(data: any): Promise<any> {
    const lowercasedHeaderData = [this.headers.map((el: any) => { return el.selectColumn })];
    const editedRowsData = data.filter((el: any) => ['Deleted', 'Changed', 'New Row'].includes(el[3]));
    this.compareChangedData = this.combineHeaderAndData(lowercasedHeaderData, this.previousTableDatas);
    let finalData = this.combineHeaderAndData(lowercasedHeaderData, editedRowsData);
    finalData = finalData?.map((el: any) => ({
      ...el,
      id: !['', null, undefined].includes(el?.id) ? el.id : 0,
      comment: null
    }));
    this.editRecords = finalData;
    this.editRecords = this.editRecords.map((item: any) => {
      const newItem = { ...item };
      for (const [key, value] of Object.entries(newItem)) {
        const item = this.headers.find((elm: any) => elm.selectColumn === key);
        if (['#NAME?', '#N/A'].includes(newItem[key]) || (!!item && item.selectColumn !== 'id') && (item?.autoGenerated && !value)) {
          newItem[key] = null;
        }
        if (item?.selectColumn == "code" && !item?.autoGenerated && [null, "", undefined].includes(newItem[key])) {
          this.isCodeNull = true
        }

      }
      return newItem;
    });
    // filter only deleted records
    this.deletedRecords = this.editRecords.reduce((acc: any[], el: any) => {
      if (el.inputstatus === 'Deleted') {
        acc.push({ id: el.id, __ROW_IDENTIFIER: el.__ROW_IDENTIFIER });
      }
      return acc;
    }, []);
    const deletedIds = this.deletedRecords?.map((el: any) => { return el.id });
    // filter only edit records
    this.editRecords = this.editRecords?.filter((el: any) => ['Changed', 'New Row'].includes(el.inputstatus));
    const result = { editRecords: this.editRecords, deletedRecords: deletedIds }
    return result;
  }

  /**
   * Handle this for comparing the previous and current table data
   * @param arr1 
   * @param arr2 
   * @returns 
   */
  public compareArrays(arr1: any, arr2: any) {
    let changes: any = [];

    arr1.forEach((obj1: any, index: number) => {
      const obj2 = arr2[index];
      let changeObj: any = { id: obj1.id, __ROW_IDENTIFIER: obj1.__ROW_IDENTIFIER, comment: obj1?.comment };

      Object.keys(obj1).forEach(key => {
        if (obj1[key] !== obj2[key] && key !== 'id' && key !== '__ROW_IDENTIFIER') {
          changeObj[key] = obj2[key];
        }
      });

      if (Object.keys(changeObj).length > 2) {
        changes.push(changeObj);
      }
    });

    return changes;
  }

  /**
   * Handle this for deleting the selecting rows
   * @param range any
   */
  public async deleteRows(range: any) {
    let rangeArray: any;
    const cellRange = range && range.includes(':') ? range.split(":") : null;;
    if (cellRange?.length > 1) {
      rangeArray = generateRange(+cellRange[0], +cellRange[1]);
      this.totalSelectedRowForDeletion += rangeArray.length;
    }
    Office.context.ui.displayDialogAsync(
      `${environment.URL}/#/confirm`,
      { height: 30, width: 40 },
      (item) => {
        const dialog = item.value;
        this.currentDialog = dialog;
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          async (arg: any) => {
            if (arg.message === 'send') {
              setTimeout(() => {
                const data = { message: `Selected ${this.totalSelectedRowForDeletion} rows will be deleted on clicking the publish button. Do you want to continue?`, yes: 'Yes', no: 'No' }
                dialog.messageChild(JSON.stringify(data));
              }, 800);
            }
            // dialog.close();
            if (arg.message === 'continue') {
              dialog.close();
              if (cellRange.length > 1) {
                await Excel.run(async (context: any) => {
                  try {
                    const cellAddress = `D${cellRange[0]}:D${cellRange[1]}`;
                    const sheet = context.workbook.worksheets.getActiveWorksheet();
                    var inputFieldRange = sheet.getRange(cellAddress);
                    await context.sync();
                    const values = Array.from({ length: rangeArray.length }, () => ['Deleted']);
                    inputFieldRange.values = values;
                    const row = sheet.getRange(range);
                    row.getUsedRange().format.fill.color = "#ffcecb";
                    await context.sync();
                    this.deletionRowRange = null;
                    hasChangedRow = true;
                  }
                  catch (err) {
                    console.log(err)
                  }
                })
              }
            }
            if (arg.message === 'close') {
              this.totalSelectedRowForDeletion -= rangeArray.length;
              dialog.close();
            }
          });
      });
  }

  public HandleSearchModel(text: any) {
    this.filteredModelList = this.modelList.filter((item: any) => {
      return item.displayName
        .trim()
        .toLowerCase()
        .includes(text.trim().toLowerCase());
    });
  }

  // Handle this function for adding the details in office settings.
  public async addEntityMetaData(filter: any, entity: any) {
    await Excel.run(async (context: any) => {
      const settings = context.workbook.settings;
      // const range = settings.getItemOrNullObject(`Entity_${this.selectedEntity.id}`);
      const sheetName = this.handleSheetName(entity?.displayName);
      const jsonObject = {
        sheetName: sheetName,
        filter: filter,
        entity_id: entity?.id,
        tableHeaders: this.headers,
        query: this.query,
        selectedHeaders: this.selectedHeaders,
        attributeNamesToReplaceCodeWithId: this.domainAttributesArray,
        visibleHeaders: this.visibleHeaders,
      };
      settings.add(`Entity_${this.selectedEntity?.id}`, JSON.stringify(jsonObject));
      await context.sync();
    }).catch((error: any) => {
      console.error(error);
    });
  }

  public flattenObject(obj: any) {
    let flattened: any = {};
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        flattened[key] = JSON.stringify(obj[key]); // Convert array to JSON string
      } else {
        flattened[key] = obj[key];
      }
    }
    return flattened;
  }

  public restoreObject(flattened: any) {
    const restored: any = {};
    for (const key in flattened) {
      if (flattened.hasOwnProperty(key)) {
        try {
          restored[key] = JSON.parse(flattened[key]); // Convert JSON string back to array/object
        } catch (e) {
          restored[key] = flattened[key]; // If not a JSON string, use the original value
        }
      }
    }
    return restored;
  }

  public async currentSheetMetaData(entity: any) {
    await Excel.run(async (context: any) => {
      this.headers = [];
      // Get the worksheet
      const settings = context.workbook.settings;

      // Sync the context to execute the queued commands
      await context.sync();
      if (!!entity?.id) {
        const range = settings.getItemOrNullObject(`Entity_${entity.id}`);
        range.load("value");
        await context.sync();
        if (!range.isNullObject) {
          this.entityMetaData = JSON.parse(range.value);
          if (!!this.entityMetaData) {
            this.entityId = this.entityMetaData?.entity_id || null;
            this.headers = this.entityMetaData?.tableHeaders;
            this.query = this.entityMetaData?.query;
            this.previousQuery = this.entityMetaData?.query;
            this.filter = this.entityMetaData?.filter;
            this.domainAttributesArray = this.entityMetaData?.attributeNamesToReplaceCodeWithId;
            this.selectedHeaders = this.entityMetaData?.selectedHeaders;
            this.selectedEntity = this.entityList.find((el: any) => el.id === this.entityId);
            this.showFilter = true;
            this.visibleHeaders = this.entityMetaData?.visibleHeaders
            this.cd.detectChanges();
          }
        }
        else {
          this.filter = []
          this.selectedHeaders = []
          this.query = null;
          this.previousQuery = null;
          this.isUpdate = false;
        }
      }
    });
  }

  public async getCurrentTableName() {
    await Excel.run(async (context: any) => {
      var sheet = context.workbook.worksheets.getActiveWorksheet();
      // Load the collection of tables in the active worksheet
      const tables = sheet.tables;
      tables.load("items/name");

      // Sync the context to execute the queued commands
      await context.sync();

      if (sheet.tables?.items?.length > 0) {
        let tableNames = sheet.tables.items[0].name;
        this.tableName = tableNames;
        return tableNames;
      }
      return true;
    });
  }

  ngOnDestroy(): void {
    this.subscription$.next();
    if (!!this.currentDialog) {
      this.currentDialog.close();
    }
    clearInterval(this.intervalId);
  }

  public async excelChangeEvent(event: any) {
    if (!changeDetectionEventInProgress) {
      changeDetectionEventInProgress = true;
      var changedCellAddress = event.address;
      let range = convertToRange(event.address);
      const cell = !!range ? range.split(":") : event.address.split(":");
      let firstLetter: any;
      let secondLetter: any;
      let rangeArray: any = [];
      let changedIndex: number;
      if (cell.length > 1) {
        firstLetter = cell[0].match(/([A-Z]+)(\d+)/);
        secondLetter = cell[1].match(/([A-Z]+)(\d+)/);
        rangeArray = generateRange(+firstLetter[2], +secondLetter[2]);
        changedIndex = secondLetter[1] ?
          this.columnIndexFromLetter(secondLetter[1]) : this.columnIndexFromLetter(firstLetter[1]);
      }
      else {
        changedIndex = this.columnIndexFromLetter(event.address.replace(/[^A-Z]/g, ''));
      }
      // var rowNumber = parseInt(changedCellAddress.match(/\d+/)[0]);
      var match = changedCellAddress.match(/([A-Z]+)(\d+)/);
      var columnLetter = match[1];
      var rowNumber = parseInt(match[2]);
      await Excel.run({ delayForCellEdit: true }, async (context: any) => {
        try {
          context.runtime.enableEvents = false;
          var sheet = context.workbook.worksheets.getActiveWorksheet();
          let inputValue = sheet.getRange(`D${rowNumber}`);


          // Load the collection of tables in the active worksheet
          // const tables = sheet.tables;
          // tables.load("items/name");
          const usedRange = sheet.getUsedRange();
          usedRange.load('rowCount, columnCount');

          const tables = sheet.tables;
          tables.load("items/name");
          // Sync the context to execute the queued commands
          await context.sync();

          const tableName = tables.items[0].name;
          if (changedIndex <= this.columnIndex[tableName]) {

            let rowCount = usedRange.rowCount;
            // Retrieve the table names
            // const tableNames = tables.items.map((table: any) => table.name);

            // let table = sheet.tables.getItem(tableNames[0]);
            let tableData: any;
            let headerRowRange: any;
            let inputStatus: any;
            if (!!rowCount) {
              // tableData = table.getDataBodyRange();
              // headerRowRange = table.getHeaderRowRange();
              // headerRowRange.load("values");
              // tableData.load("values");
              const rowRange = sheet.getRangeByIndexes(rowNumber - 1, 0, 1, usedRange.columnCount);
              rowRange.load('values');
              await context.sync();
              inputStatus = rowRange.values[0][3];
            }

            if (["CellInserted", "RowInserted"].includes(event.changeType) && !event.details) {
              var range = sheet.getRange(event.address);
              const cellAddress = `D${firstLetter[2]}:D${secondLetter[2]}`;
              const inputFieldRange = sheet.getRange(cellAddress);
              inputFieldRange.load('values');
              await context.sync();
              const values = Array.from({ length: rangeArray.length }, () => inputStatus === 'Deleted' ? ['Deleted'] : inputStatus !== '' ? ['Changed'] : ['New Row']);
              inputFieldRange.values = values;
              range.format.fill.color = "#ffd3a9";
            }
            // if (columnLetter === 'D' && event.details?.valueAfter === 'New Row') {
            //   // inputValue.values = [['New Row']];
            //   var range = sheet.getRange("A" + rowNumber + ":XFD" + rowNumber);
            //   range.getUsedRange().format.fill.color = "#ffd3a9";
            //   await context.sync();
            // }
            if (['CellDeleted', 'ColumnDeleted'].includes(event.changeType) || event.changeType == 'RangeEdited' && !event.details) {
              await context.sync();
              // if (!headerRowRange.values[0].includes("Code")) {
              //   Office.context.ui.displayDialogAsync(
              //     `${environment.URL}/#/missing/column`, { height: 30, width: 20 },
              //     (asyncResult: any) => {
              //       const dialog = asyncResult.value;
              //       dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
              //         // if (arg.message === 'send') {
              //         //   const data = { message: "Critical columns missing : 'Code'. Detaching from worksheet", yes: 'Close' }
              //         //   dialog.messageChild(JSON.stringify(data));
              //         // } else {
              //         dialog.close();
              //         // }
              //       })
              //     }
              //   );
              // }
            }

            if (event?.changeType === 'RangeEdited' && !!firstLetter && !!secondLetter) {
              const range = sheet.getRange(event.address)
              try {
                const cellAddress = `D${firstLetter[2]}:D${secondLetter[2]}`;
                const inputFieldRange = sheet.getRange(cellAddress);
                inputFieldRange.load('values');
                await context.sync();
                const values = Array.from({ length: rangeArray.length }, () => inputStatus === 'Deleted' ? ['Deleted'] : inputStatus !== '' ? ['Changed'] : ['New Row']);
                inputFieldRange.values = values;
              } catch (error) {
                console.error('Error updating cell values:', error);
              }
              if (!["D", "B"].includes(columnLetter)) {
                range.format.fill.color = "#ffd3a9";
                await context.sync();
              }
            }
            if (!changedCellAddress.startsWith("Sheet1!A") && !!event.details && event?.details?.valueAfter !== event?.details?.valueBefore && event?.changeType === 'RangeEdited') {
              if (!["D", "B"].includes(columnLetter)) {
                if (inputStatus !== 'Deleted') {
                  hasChangedRow = true;
                  const val = inputStatus !== '' ? [['Changed']] : [['New Row']];
                  inputValue.values = val;
                  inputValue.getUsedRange().format.font.color = "#000000";
                  // var range = sheet.getRange("A" + rowNumber + ":XFD" + rowNumber);
                  var range = sheet.getRange(event.address);
                  range.format.fill.color = "#ffd3a9";
                  await context.sync();
                }
              }
            }

            await context.sync();
            changeDetectionEventInProgress = false;
          }
          else {
            changeDetectionEventInProgress = false;
          }
        }
        catch (err) {
          console.log(err, 'changeevt')
        } finally {
          context.runtime.enableEvents = true;
        }

        return context.sync();
      }).catch(function (error: any) {
        changeDetectionEventInProgress = false;
        console.log("Error: " + error);
        if (error instanceof OfficeExtension.Error) {
          console.log("Debug info: " + JSON.stringify(error.debugInfo));
        }
      });
    }
  }


  columnIndexFromLetter(letter: string): number {
    letter = letter.toUpperCase();
    let index = 0;
    for (let i = 0; i < letter.length; i++) {
      index = index * 26 + (letter.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
    }
    return index;
  }

  public async logout() {

    //   Office.context.ui.displayDialogAsync(`${environment.URL}/#/loader`, { height: 20, width: 20 },
    //   (item) => {
    //     const dialog = item.value;
    //     this.currentDialog = dialog;
    //     dialog.addEventHandler(Office.EventType.DialogMessageReceived, async (arg: any) => {
    //     dialog.messageChild("Loading Started");
    //     })
    // })

    await Excel.run(async (context: any) => {

      //   const workbook = context.workbook;
      //   const sheets = workbook.worksheets;

      //   // Load the collection of worksheets
      //   sheets.load('items');
      //   await context.sync();

      //   //Delete Settings
      const settings = context.workbook.settings;
      settings.add('roles', this.roles)
      //   if (settings.items.length == 0) {
      //   } else {
      //     for (let i = 0; i < settings.items.length; i++) {
      //       let deleteSetigs = settings.getItemOrNullObject(settings.items[i].key);
      //       deleteSetigs.delete()
      //     }
      //   }

      //   //Add a blank sheet so that the worksheets is not empty
      //   const newSheet = sheets.add('Sheet1');
      //   newSheet.activate();

      //   // Iterate over each sheet and delete it
      //   sheets.items.forEach((sheet: any) => {
      //     sheet.delete();
      //   });

      //   await context.sync();

    }).catch((error: any) => {
      console.error(error);
    });
    localStorage.clear();
    this.route.navigate(['/landing']);
  }

  public onSavedFilter() {
    let dialog: any;
    this.previousQuery = this.query
    Office.context.ui.displayDialogAsync(
      `${environment.URL}/#/savedFilter/${this.entity.id}`,
      { height: 80, width: 60 },
      (item) => {
        dialog = item.value;
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          async (arg: any) => {
            dialog.close();
            if (arg.message != 'close') {
              this.savedFilter = JSON.parse(decodeURIComponent(arg.message));
              this.query = this.savedFilter.filterQuery;
              this.isUpdate = true;
              setTimeout(() => {
                this.openDialogBox(this.entity);
              }, 100);
            }
          });
      });
  }

  async removeEventHandler() {
    await Excel.run(this.onChangedEventHandler.context, async (context: any) => {
      this.onChangedEventHandler.remove();
      await context.sync();
      this.onChangedEventHandler = null;
    });
  }

  async manageRowIdentifierRow(sheet: Excel.Worksheet): Promise<void> {
    const columnName = '__ROW_IDENTIFIER';
    const startRowIndex = 1;
    const columnIndex = this.headers.findIndex((header: any) => header.name === columnName);

    if (columnIndex !== -1 && columnIndex < this.headers.length && this.headers[columnIndex].name === '__ROW_IDENTIFIER') 
    {
      const formula = `=ROW()`;
  
      // Apply formula '=ROW()' to the column in the Excel sheet
      const columnRange = sheet.getRangeByIndexes(startRowIndex, columnIndex, 1, 1);
      columnRange.formulas = [[formula]];
      columnRange.load(['formulas']);
    }
  }

}



class ExcelHelper {
  static getColumnLetter(columnIndex: number): string {
    let dividend = columnIndex + 1;
    let columnName = '';
    let modulo;

    while (dividend > 0) {
      modulo = (dividend - 1) % 26;
      columnName = String.fromCharCode(65 + modulo) + columnName;
      dividend = Math.floor((dividend - modulo) / 26);
    }

    return columnName;
  }
}

function generateRange(start: any, end: any) {
  const range = [];
  for (let i = start; i <= end; i++) {
    range.push(i);
  }
  return range;
}

function convertToRange(cellList: any) {
  // Split the input string into an array of cell references
  let cells = cellList.split(',');

  // Initialize variables to find min and max row numbers and column letters
  let minRow = Number.MAX_SAFE_INTEGER;
  let maxRow = 0;
  let startColumn = '';
  let endColumn = '';

  // Iterate through each cell reference
  if (cells.length > 1) {
    cells.forEach((cell: any) => {
      // Extract row number and column letter from the cell reference
      let match = cell.match(/([A-Z]+)(\d+)/);
      if (match) {
        let colLetter = match[1];
        let rowNumber = parseInt(match[2]);

        // Determine the minimum and maximum row numbers
        if (rowNumber < minRow) {
          minRow = rowNumber;
        }
        if (rowNumber > maxRow) {
          maxRow = rowNumber;
        }

        // Take the column letter (assuming all cells are in the same column)
        if (startColumn === '') {
          startColumn = colLetter;
        }
        // Always update the endColumn to ensure the last column is taken
        endColumn = colLetter;
      }
    });

    // Construct the range string
    let range = startColumn + minRow + ':' + endColumn + maxRow;
    return range;
  } else {
    return cellList
  }
}

function arraysEqual(arr1: any[], arr2: any[], headers: any): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

