import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  public token: any;

  constructor(
    private authService: AuthService
  ) {
    this.token = localStorage.getItem('token')  || null;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.includes('/protocol/openid-connect/token'))
      {
        const modifiedRequest = request.clone({
          setHeaders: {
            'Content-Type': 'application/x-www-form-urlencoded',
          }
        });
        return next.handle(modifiedRequest);
      }
      else
      {
    return this.authService.getToken().pipe(
      switchMap((token: any) => {
        token = !!token ? token : this.token;
        // Clone the request and add custom headers

       const modifiedRequest = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json' // Adjust content type if needed
          }
        });
        // Pass the modified request to the next handler
        return next.handle(modifiedRequest);
      })
    );
  }
  }
}