import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class IndexeddbService {
    private db!: IDBDatabase;

    constructor() {
        // this.initDB();
    }

    public initDB() {
        const request = indexedDB.open('lookupDB');
        request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
            this.db = (event.target as IDBOpenDBRequest).result;
        };

        request.onsuccess = (event: Event) => {
            this.db = (event.target as IDBOpenDBRequest).result;
        };

        request.onerror = (event: Event) => {
            console.error('Database error:', (event.target as IDBOpenDBRequest).error);
        };
    }

    async addStore(storeName: string, keyPath: string) {
        const version = this.db?.version + 1;
        this.db?.close();
        const request = indexedDB.open('lookupDB', version);

        request.onupgradeneeded = async (event: IDBVersionChangeEvent) => {
            this.db = (event.target as IDBOpenDBRequest).result;

            if (this.db.objectStoreNames.contains(storeName)) {
                // Wrap the deleteObjectStore call in a promise to await its completion
                await new Promise<void>((resolve) => {
                    this.db.deleteObjectStore(storeName);
                    // Resolve immediately as deleteObjectStore is synchronous
                    resolve();
                });
            }

            this.db.createObjectStore(storeName, { keyPath });
        };

        return new Promise<void>((resolve, reject) => {
            request.onsuccess = (event: Event) => {
                this.db = (event.target as IDBOpenDBRequest).result;
                resolve();
            };

            request.onerror = (event: Event) => {
                reject((event.target as IDBOpenDBRequest).error);
            };
        });
    }

    addBulkData(storeName: string, data: any[]): Promise<void> {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);

            data.forEach(item => {
                store.add(item);
            });

            transaction.oncomplete = () => {
                resolve();
            };

            transaction.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    getAllData(storeName: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([storeName], 'readonly');
            const store = transaction.objectStore(storeName);
            const request = store.getAll();

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    getDataByKey(storeName: string, key: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([storeName], 'readonly');
            const store = transaction.objectStore(storeName);
            const request = store.get(key);

            request.onsuccess = () => {
                resolve(request.result);
            };

            request.onerror = (event) => {
                reject((event.target as IDBRequest).error);
            };
        });
    }

    getStores() {
        let lookups = this.db?.objectStoreNames;
        const objValues = new Set(Object.values(lookups));
        return objValues
    }

    closeDb() {
        this.db.close();
    }

    public async ensureDbOpen() {
        this.db?.close();
        await new Promise<void>((resolve, reject) => {
            const request = indexedDB.open('lookupDB');
            request.onsuccess = (event: Event) => {
                this.db = (event.target as IDBOpenDBRequest).result;
                resolve();
            };
            request.onerror = (event: Event) => {
                reject((event.target as IDBOpenDBRequest).error);
            };
        });
    }

}