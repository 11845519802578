import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EntityComponent } from './components/entity/entity.component';
import { FilterDialogComponent } from './components/filter-dialog/filter-dialog.component';
import { TokenAuthComponent } from './components/token-auth/token-auth.component';
import { AddAnnotationComponent } from './components/add-annotation/add-annotation.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { ColumnMissingDialogComponent } from './shared/column-missing-dialog/column-missing-dialog.component';
import { LoginComponent } from './components/login/login.component';
import { LoginDialogueComponent } from './components/login-dialogue/login-dialogue.component';
import { AuthGuard } from './services/auth.guard';
import { SavedFilterlistComponent } from './components/filter-list/saved-filterlist/saved-filterlist.component';
import { LoaderComponent } from './shared/loader/loader.component';

const routes: Routes = [
  {path: 'landing', component: LoginComponent},
  {path: 'login/:token', component: LoginDialogueComponent},
  {path: 'localAuth', component: TokenAuthComponent},
  {path: '', component: EntityComponent,canActivate: [AuthGuard]},
  {path: 'publish', component: AddAnnotationComponent},
  {path: 'confirm', component: ConfirmDialogComponent},
  {path: 'missing/:message', component: ColumnMissingDialogComponent},
  {path: 'filter/:model_id/:entity_id/:token', component: FilterDialogComponent},
  {path: 'savedFilter/:entityId', component: SavedFilterlistComponent},
  {path: 'loader', component: LoaderComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
