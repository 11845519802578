<div class="box">
    <label class="text-secondary-dark m-2 font-bold text-lg">
        <mat-icon style="height: 30px; width: 30px; font-size: 28px;padding-top: 5px;"
            class="text-primary-dark justify-self-center">warning</mat-icon> Warning
    </label>

    <div>
        <div class="grid grid-flow-row m-2">
            <span class="font-thin text-base">{{this.message}}</span>
        </div>
    </div>

    <div class="btngrp">
        <button mat-button class="square-button" (click)="onConfirm()">
            Ok
        </button>
    </div>
</div>