import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  constructor(private authService: AuthService, private ngZone: NgZone,private route:Router

  ) { }
  onSubmit() {
    Office.context.ui.displayDialogAsync(
      `${environment.URL}/#/login/token`,
      { height: 70, width: 50 },
      (item) => {
        const dialog = item.value;
        dialog.addEventHandler(
          Office.EventType.DialogMessageReceived,
          async (arg: any) => {
            console.log(arg.message)
            localStorage.setItem('token', arg.message);
            this.updateAccessToken(arg.message);
            this.ngZone.run(() => {
              this.route.navigate(['/']);
            })
            dialog.close();
          });
      });
  }
  public updateAccessToken(token: any) {
    this.authService.updateToken(token);
  }
}
