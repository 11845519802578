import { Component, Inject } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { FilterListService } from "../services/filter-list.service";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ConfirmMatDialogComponent } from "src/app/shared/confirm-mat-dialog/confirm-mat-dialog.component";
@Component({
  selector: "sp-add-filterlist",
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule],
  templateUrl: "./add-filterlist.component.html",
  styleUrls: ["./add-filterlist.component.scss"],
})
export class AddFilterlistComponent {
  formData = {};
  saveForm: FormGroup;
  message: string = "";
  constructor(public dialogRef: MatDialogRef<AddFilterlistComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, private filterListService: FilterListService,
    private fb: FormBuilder
  ) {
    this.saveForm = this.fb.group({
      name: [this.data?.item?.name || '', [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern("^(?=[a-zA-Z0-9])[a-zA-Z0-9 _#-]+$")]],
      description: [this.data?.item?.description || '', [Validators.minLength(3), Validators.maxLength(50)]]
    });

  }

  onClose() {
    this.dialogRef.close();
  }
  onReset() {
    this.saveForm.reset();
  }
  onSubmit() {
    if (this.saveForm.valid) {
      if (this.data.action == 'Add') {
        let data = { ...this.data.item, ...this.saveForm.value }
        this.filterListService.addFilters(data).subscribe({
          next: (result) => {
            this.message = ""

           const dialogRefe = this.dialog.open(ConfirmMatDialogComponent, {
              data: { message: 'Filter saved successfully',icon:'check_circle',title:'Success'},
              width: "250px",
              height: "100px",
              disableClose: true,
            });
            setTimeout(() => {
              dialogRefe.close();  
            }, 1500);

            this.dialogRef.close();
          },
          error: (err) => {
            if (err.error.errorCode == "DB_UK-008") {
              this.message = "Filter with same name already exists"
            }
            else {
              this.message = "Save Failed"
            }
          },
        });
      }
      else {
        let data = { ...this.data.item, ...this.saveForm.value }
        this.filterListService.editFilter(data).subscribe({
          next: (result) => {
            this.message = "";
            const dialogRefe = this.dialog.open(ConfirmMatDialogComponent, {
              data: { message: 'Filter Updated successfully',icon:'check_circle',title:'Success'},
              width: "250px",
              height: "100px",
              disableClose: true,
            });
            setTimeout(() => {
              dialogRefe.close();  
            },1500);
            this.dialogRef.close();

          },
          error: (err) => {

            if (err.error.errorCode == "DB_UK-008") {
              this.message = "Filter with same name already exists";
            }
            else {
              this.message = "Edit failed.";
            }
          },
        });
      }
    }
  }
}
