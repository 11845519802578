import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { QueryColumns } from '../shared/filter-conditions/filter-conditions.component';

@Injectable({
  providedIn: 'root'
})
export class EntityGridService {

  constructor() { }

  public getFilterColumns(headers: any[]): QueryColumns[] {
    return headers
      .filter((item) => item.isFilterable)
      .map((col: any) => {
        return {
          name: col.formType=="LOOKUP" ? col.selectColumn:col.column,
          displayName: col.name,
          formType: col.formType,
          referencedTableId: col.referencedTableId,
          dataType: col.dataType || "string",
          options: col.dataType === "category" ? col.options : undefined,
        };
      });
  }

  public getGridHeaders(
    entity: any,
    showSystemAttributes: boolean,
    lookupSettings: string
  ): any[] {
    return entity.attributes
      // .filter((item :any) => item.showInForm)
      .filter((item: any) => (!item.systemAttribute || ['id', 'validationstatus'].includes(item.name)))
      // .filter((item: any) => {
      //   return (
      //     item.permission !== "DENY" &&
      //     item.constraintType !== "PRIMARY_KEY" &&
      //     (item.systemAttribute == false ||
      //       (item.systemAttribute && showSystemAttributes))
      //   );
      // })
      .map((attr: any) => {
        return {
          column:
            attr.type === "LOOKUP"
              ? lookupSettings === "code" || lookupSettings === undefined
                ? `${attr.name}_code`
                : lookupSettings === "codename"
                  ? `${attr.name}_code,${attr.name}_name`
                  : `${attr.name}_name,${attr.name}_code`
              : attr.name,
          name: attr.displayName || attr.name,
          selectColumn:attr.name,
          canAutoResize: true,
          autoGenerated: attr.dataType === 'AUTO' ? true : attr.autoGenerated,
          isSortable: true,
          permission: attr.permission,
          isFilterable: true,
          dataType: this.getColumnDataType(attr),
          isAuto: attr.dataType == 'AUTO' ? true : false,
          options: this.getOptions(attr),
          width: attr.width || 0,
          formType: attr.type,
          dateFormat: !!attr.formatter ? attr.formatter : '',
          systemAttribute:attr.systemAttribute,
          referencedTableId: attr?.referencedTableId || null,
          pipe: (data: any) => {
            return this.getTransform(data, attr);
          },
          isLink: attr.dataType === "LINK",
        };
      });
  }
  
  private getColumnDataType(
    attr: any
  ): "string" | "number" | "date" | "category" | "boolean" {
    switch (attr.dataType) {
      case "STRING_VAR":
      case "TEXT":
      case "LINK":
        return "string";
      case "TIME_STAMP":
        return "date";
      case "BOOLEAN":
        return "boolean";
      case "INTEGER":
      case "SERIAL":
      case "DECIMAL":
      case "AUTO":
        return "number";
      default:
        return "string";
    }
  }

  private getOptions(
    attr: any
  ): { name: string; value: any }[] | undefined {
    let options = undefined;
    if (attr.dataType === "BOOLEAN") {
      options = [
        { name: "True", value: true },
        { name: "False", value: false },
      ];
    }
    return options;
  }

  private getTransform(data: any, attr: any) {
    if (attr.dataType === "BOOLEAN") {
      return data ? "True" : "False";
    } else if (attr.dataType === "INTEGER" || attr.dataType === "DECIMAL") {
      if (attr.formatter === "(####)") {
        if (data < 0) {
          return `(${data * -1})`;
        }
      }
      return data;
    } else if (attr.dataType === "TIME_STAMP" && data !== null) {
      let formattedData = data;
      try {
        formattedData = moment(data).format(attr.formatter || "MM/DD/YYYY");
      } catch (err) {
        console.error(err);
      }
      return formattedData;
    }
    return data;
  }

  public extractExtraData(selectedItem: any, selectedEntity: any) {
    let extraData: Map<string, object> = new Map<string, object>();
    selectedEntity.attributes
      .filter((item: any) => item.type == "LOOKUP" && item.permission == "UPDATE")
      .forEach((attribute: any) => {
        const attribute_name = `${attribute.name}_name`;
        const attribute_code = `${attribute.name}_code`;
        if (
          selectedItem.hasOwnProperty(attribute_name) &&
          selectedItem.hasOwnProperty(attribute_code)
        ) {
          extraData.set(attribute_name, selectedItem[attribute_name]);
          extraData.set(attribute_code, selectedItem[attribute_code]);
        }
      });
    return extraData;
  }

}

